.c__copy-button-container {
  position: relative;
  display: inline-block;
}

.c__copy-button {
  width: 22px;
  height: 22px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;

  & > svg {
    stroke: var(--infoColor);
  }
}

.c__tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  text-transform: unset;
}

.c__copy-button-container:hover .c__tooltip {
  visibility: visible;
  opacity: 0.8;
  text-align: center;
}
