.notificationTemplateView {
  &Wrapper {
    .c__loading__container {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 15;

      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
    }
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 11;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transform: all 0.3s;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  position: fixed;
  top: 0;
  left: 100%;
  z-index: 12;

  display: flex;
  flex-direction: column;

  min-width: calc(100vw - 280px);
  height: 100vh;
  background-color: var(--bgPrimary);
  border: 1px solid var(--lineColor);
  transition: all 0.3s;
  overflow: hidden;

  &.active {
    left: 280px;
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 24px;
    border-bottom: 1px solid var(--lineColor);
    & > div > svg {
      cursor: pointer;
    }
    & > p {
      color: var(--titleColor);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      text-transform: uppercase;
    }
  }

  &__info {
    overflow-y: auto;
    flex: 1;
    padding: 24px 24px 12px 24px;

    &--wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &--top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      & > div {
        flex: 1;
      }
    }

    &--content {
      &--label {
        color: var(--titleColor);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
      }
      &--error {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
        color: var(--errorColor);
      }
    }

    &--upload {
      margin-top: 12px;
      &--label {
        color: var(--titleColor);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
      }
      &--error {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
        color: var(--errorColor);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 24px;
    padding: 24px;
    border-top: 1px solid var(--lineColor);
    & > button {
      cursor: pointer;
    }
  }
}
