.updateModal {
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    height: 100svh;

    z-index: 11;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: all 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .updateModalForm {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 22;

    display: flex;
    flex-direction: column;

    min-width: 620px;
    width: 43%;
    max-width: 43%;

    height: 100vh;
    height: 100svh;
    background-color: var(--bgPrimary);
    border: 1px solid var(--lineColor);
    transition: all 0.3s;
    overflow: hidden;

    &.active {
      left: calc(100% - max(620px, 43%));
    }

    &__header {
      height: 76px;

      display: flex;
      align-items: center;

      padding: 24px;
      gap: 10px;
      border-bottom: 1px solid var(--lineColor);

      & > div {
        width: 24px;
        height: 24px;
        cursor: pointer;
        & > img {
          display: block;
          width: 100%;
          object-fit: contain;
        }
      }

      & > p {
        color: var(--titleColor);
        font-size: 20px;
        font-weight: 500;
        line-height: 140%; /* 28px */
        text-transform: uppercase;
      }
    }

    &__content {
      overflow-y: auto;
      flex: 1;

      padding: 24px;
    }

    &__actions {
      padding: 12px 16px 32px 16px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.05);

      & > button {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
