.review-n-payment-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  font-size: 14px;
  .section {
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background-color: var(--bgPrimary);
    .title {
      font-family: Gotham;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      text-transform: uppercase;
      color: var(--titleColor);
    }
  }
  .review-section {
    min-width: 622px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    .student-info {
      &-wrapper {
        width: 100%;
      }
      gap: 10px;
      align-self: stretch;
      width: 100%;
      &-name {
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
        background-color: var(--bgSecondary);
        text-transform: uppercase;
        font-weight: 500;
      }
      .app-table {
        .table-header {
          background-color: var(--bgPrimary);
          .table-header-item {
            font-weight: 500;
          }
        }
      }

      .review_body_header {
        background: var(--bgSecondary);
        padding: 10px;
        min-height: 40px;
        height: 40px;

        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        text-transform: uppercase;
      }

      .review_body_body {
        display: flex;
        flex-direction: column;

        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-transform: uppercase;

        &-row {
          padding: 12px;

          display: grid;
          grid-template-columns: repeat(19, minmax(0, 1fr));
          column-gap: 10px;

          border-bottom: 1px solid var(--lineColor);
          text-transform: uppercase;

          .header {
            overflow: hidden;
            color: var(--titleColor);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
            text-transform: uppercase;
          }

          & > div > .c__select {
            padding-top: 0px;
            padding-bottom: 0px;
          }

          .debit_custom {
            display: flex;
            justify-content: flex-end;
            gap: 4px;
            align-items: flex-start;

            .icon {
              stroke: var(--warningColor);
              cursor: pointer;
            }

            .tooltip-trigger {
              .tooltip-top {
                left: -115px;
                bottom: 110%;
              }
            }
          }

          .span1 {
            grid-column: span 1;
          }

          .span2 {
            grid-column: span 2;
          }

          .span3 {
            grid-column: span 3;
          }

          .span4 {
            grid-column: span 4;
          }

          .span5 {
            grid-column: span 5;
          }
        }

        &-header {
          padding: 10px;

          min-width: 50px;
          min-height: 50px;

          display: flex;
          align-items: center;
          width: 100%;

          color: var(--titleColor);
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          text-transform: uppercase;
        }

        .row1 {
          grid-column: span 1;
        }

        .row2 {
          grid-column: span 2;
        }

        .row3 {
          grid-column: span 3;
        }

        .row4 {
          grid-column: span 4;
        }

        .row5 {
          grid-column: span 5;
        }
      }
    }
    .total-section {
      display: flex;
      padding-top: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      .item {
        font-weight: 500;
        display: flex;
        padding: 0px 12px;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        justify-content: space-between;
        .title {
          font-weight: 500;
          font-size: 14px;
        }
        .total-price {
          font-weight: 500;
          font-size: 14px;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 33.6px */
          text-transform: uppercase;
        }
        &.discount {
          div {
            color: var(--subTitleColor);
            margin-left: 10px;
          }
          .value {
            color: var(--titleColor);
          }
        }
      }
    }
  }
  .payment-section {
    // min-width: 444px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    .payment-method-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 12px;
      width: 100%;
      .card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        border-radius: 8px;
        border: 1px solid transparent;
        padding: 12px;
        cursor: default;

        // &:hover {
        //   background-color: var(--bgTertiary);
        // }

        &.active {
          background-color: var(--bgPrimary);
        }

        .name {
          color: var(--titleColor);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          text-transform: uppercase;
        }

        .logo {
          border-radius: 2px;

          object-fit: contain;
        }

        .square {
          width: 25px;
          height: 14px;

          min-width: 25px;
          min-height: 14px;
        }

        .round {
          width: 16px;
          height: 16px;

          min-width: 16px;
          min-height: 16px;
        }

        .bg-white {
          background: var(--titleColor);
        }
      }
    }
  }
  .pay-now-container {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    align-items: center;
    svg {
      stroke: var(--warningColor);
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
}

.c__select-option-label {
  text-transform: capitalize;
}

.next-bill-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.next-bill-content {
  display: flex;
  padding: 12px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    text-transform: uppercase;
  }
  &-table {
    width: 100%;
    .table-header {
      display: flex;
      padding: 10px 12px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      background-color: var(--bgSecondary);
      justify-content: space-between;
      &-th {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        text-transform: uppercase;
      }
    }
    .table-row {
      display: flex;
      padding: 10px 12px;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      border-bottom: 1px solid var(--lineColor);
      &-tr {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        text-transform: uppercase;
      }
    }
    .total-price {
      display: flex;
      padding: 10px 12px;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      text-transform: uppercase;
    }
  }
}
