.reportStudentBookingSummary {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    & > div {
      flex: 1;
    }

    & > .btn {
      margin-top: 6px;
    }
  }
  &__content {
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    & .c__loading__container {
      position: absolute;
      top: 18%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      & > .c__input-search {
        width: 286px;
      }
    }

    &__days {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;
      margin-top: 24px;
    }
    &__day {
      color: var(--subTitleColor);
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.2s;
      border-radius: 8px;
      padding: 4px 8px;

      &:hover,
      &.active {
        color: var(--titleColor);
        background-color: var(--primaryColor);
      }
    }

    &__table {
      margin-top: 24px;
      overflow-x: auto !important;

      &::-webkit-scrollbar {
        height: 5px !important;
      }

      & > table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;

        & > thead {
          & > tr {
            & > th {
              vertical-align: baseline;
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              text-transform: uppercase;
              background: var(--bgSecondary);
              padding: 10px;
              text-align: left;
              min-width: 175px;
              width: 175px;

              &:first-child {
                min-width: 175px !important;
                width: 175px !important;
                position: -webkit-sticky;
                position: sticky;
                left: 0px;
                background: var(--bgSecondary);
              }
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-transform: uppercase;
              padding: 12px;
              border-bottom: 1px solid var(--lineColor);
              min-width: 175px;
              width: 175px;

              &:first-child {
                min-width: 175px !important;
                width: 175px !important;
                position: -webkit-sticky;
                position: sticky;
                left: 0px;
                background: var(--bgPrimary);
              }
            }
            &:last-child {
              & > td {
                border-bottom: none;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
