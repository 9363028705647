.dashboard-content-detail {
  background-color: var(--bgSecondary);
  .dashboard-content {
    padding: 20px 30px 30px 30px;
  }
  .search-component {
    position: absolute;
    top: 44px;
    left: 0%;
    border-radius: 8px;
    background: var(--bgPrimary);
    border: 1px solid var(--lineColor);
    width: 100%;
    z-index: 15;
    transition: display 0.3s ease-in;
    height: 0;
    overflow: hidden;
    display: none;
  }
  .search-component.open {
    border: 1px solid var(--lineColor);
    transition: display 0.3s ease-out;
    padding: 24px;
    display: block;
    top: 44px;
    height: 373px;
  }
  .footer-search {
    display: flex;
    justify-content: flex-end;
  }
  .search-btn {
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 24px;
  }
  .date-search {
    display: flex;
    justify-content: space-between;
  }
}
.search-content {
  background-color: var(--bgSecondary);
  // height: calc(100vh - 84px);
  // overflow-y: scroll;
  padding-bottom: 50px;
}
.search-result-container {
  padding: 30px;
  border: 1px solid var(--lineColor);
  border-radius: 8px;
  background-color: var(--bgPrimary);
  .header {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 24px;
    div {
      font-weight: 500;
    }
  }
  .no-result {
    display: flex;
    font-size: 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
.calendar--wrapper {
  position: relative;
}
.loading-page {
  display: flex;
  height: calc(100vh - 216px);
  justify-content: center;
  align-items: center;
  background: var(--bgPrimary);
  z-index: 12;
  opacity: 0.35;
}
.color-setting-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 16px 0;
  .vacancy-input {
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid var(--lineColor);
    border-radius: 8px;
    padding: 5px;
    height: 33px;
    margin-bottom: 3px;
    span {
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      &.text {
        margin-left: 10px;
      }
    }
    input {
      min-width: 30px;
      max-width: 40px;
      background-color: transparent;
      outline: 0;
      text-align: center;
      margin-left: 8px;
    }
  }
}
.term-options-container-content {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--lineColor);
  background: var(--bgPrimary);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);
  margin-top: 10px;
  height: 250px;
  overflow-y: scroll;
  min-width: 230px;
  .term-option-item {
    display: flex;
    padding: 10px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    align-self: stretch;
    border-bottom: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    &.active {
      background: var(--bgTertiary);
      svg {
        width: 22px;
        height: 22px;
      }
    }
    &.last {
      border-bottom: 0;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      text-transform: capitalize;
    }
    span {
      color: var(--subtitleColor);
      font-family: Gotham;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
