.filter-student-component {
  position: fixed;
  background-color: var(--bgSecondary);
  right: 0;
  top: 0;
  border-left: 1px solid #40404a;
  border-bottom: 1px solid #40404a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh);
  z-index: 14;
  max-width: 420px;
  width: 0;
  transition: all 0.3s ease-out;
  display: none;
  &.open {
    width: 420px;
    transition: all 0.3s ease-in;
    left: calc(100vw - 420px);
    display: block;
  }
  .filter-title {
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 8px;
  }
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--lineColor);
    &-text {
      padding: 0;
      border-bottom: none;
      svg {
        cursor: pointer;
      }
    }
  }
  .reset-label {
    color: var(--primaryColor);
    font-size: 16px;
    cursor: pointer;
  }
  .filter-body {
    padding: 24px;
    height: calc(100vh - 150px);
    overflow-y: scroll;
    .filter-field {
      display: flex;
      gap: 16px;
      align-items: center;
      .c__select {
        width: 90%;
      }
      &-active-wrapper {
        border-radius: 8px;
        padding: 10px 20px;
        height: 42px;
        width: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--lineColor);
        margin-top: 7px;
        cursor: pointer;
        &.active {
          background-color: var(--primaryColor);
        }
      }
      .date-field {
        width: 90%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .apply-btn {
    font-size: 16px;
    text-transform: uppercase;
    width: auto;
    white-space: nowrap;
  }
  .footer-filter {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-top: 1px solid #40404a;
  }
}
