.reportPrintButton {
  display: block;
  position: relative;
  &Main {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    height: 42px;
    padding: 10px 12px;

    color: var(--titleColor);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
  }

  &Popper {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 100%;
    width: 164px;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);
    padding: 8px 0px;

    overflow-y: auto;
    max-height: 218px;

    display: flex;
    flex-direction: column;

    &Item {
      display: flex;
      align-items: center;
      column-gap: 6px;

      color: var(--titleColor);
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      padding: 10px 16px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: var(--bgTertiary);
      }
    }
  }
}
