.location-list-wrapper {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 2px;
  flex: 1 0 0;
  flex-wrap: wrap;
  .location-item {
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 99px;
    border: 1px solid var(--lineColor);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    &.rest-location-text {
      cursor: pointer;
      position: relative;
      background-color: var(--bgTertiary);
    }
    .rest-locations-popup {
      &-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 12;
        display: flex;
        width: 134px;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        max-height: 240px;
        overflow-y: scroll;

        border-radius: 8px;
        border: 1px solid var(--lineColor);
        background: var(--bgPrimary);
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);
      }
      &-item {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        background: var(--bgPrimary);
      }
    }
  }
}
