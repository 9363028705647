.student_route {
  .tab-student-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;

    position: relative;

    .pageLoading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      padding: 50px 30px;

      &-main {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        & > span > span {
          background-color: var(--primaryColor) !important;
        }
      }
    }
  }
}
