.classTemplateDetailPage {
  .icon {
    width: 30px;
    height: 30px;
    padding: 3px;
    cursor: pointer;
    & svg {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: contain;
    }
  }

  .classTemplateDetail {
    &__card {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      &-toggle {
        & .customToggle {
          display: flex;
          align-items: center;
          column-gap: 12px;
          grid-column: span 3 / span 3;

          & > p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
          }
        }
      }
      &-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        &-duration {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
      &-fields-edit {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 24px;
        .pick-color-container {
          width: 100%;
          height: 100%;
          position: relative;
          cursor: pointer;
          .pick-color {
            border-radius: 8px;
            height: 54px;
            margin-top: 20px;
          }
        }
      }
      &-edit {
        row-gap: 0px;
        & .classTemplateDetail__card-toggle {
          margin-bottom: 20px;
        }
      }
    }
    .info-text {
      color: var(--titleColor);
      font-size: 16px;
      font-weight: 500;
      color: var(--warningColor);
      margin: 12px 0 24px 0;
    }
    .btn {
      align-items: center;
      column-gap: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      & .plusIcon {
        height: 22px;
        svg {
          width: 22px;
          height: 22px;
        }
      }

      & p {
        color: var(--titleColor);
        font-size: 16px;
        padding-top: 2px;
        line-height: 16px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
}
