.reportTimetableByTeacher {
  &__loadingExport {
    .c__loading__container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 10;
      & .c__loading__container-main {
        height: 100%;
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__search {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    &__fields {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;
    }

    & > .btn {
      margin-bottom: 12px;
    }
  }

  &__content {
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    & .c__loading__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      &--actions {
        & .exportButton {
          border-radius: 8px;
          border: 1px solid var(--lineColor);
          background: var(--bgPrimary);
          padding: 10px 12px;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          color: var(--whiteColor);
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          text-transform: capitalize;

          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            border-color: var(--whiteColor);
          }
        }
      }
    }

    &__date {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 12px;
      margin-top: 24px;
      &--arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        aspect-ratio: 1;
        border-radius: 8px;
        border: 1px solid var(--lineColor);
        padding: 6px;
        cursor: pointer;
        &:disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
      &--text {
        color: var(--titleColor);
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
      }
    }

    &__table {
      margin-top: 24px;
      overflow-x: auto !important;
      position: relative;
      &::-webkit-scrollbar {
        height: 5px !important;
      }

      &--noData {
        color: var(--warningColor);
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.5px;

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px auto 0px;
      }

      & > table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;

        & > thead {
          & > tr {
            & > th {
              vertical-align: baseline;
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              text-transform: uppercase;
              background: var(--bgSecondary);
              padding: 10px;
              text-align: left;
              min-width: 380px;
              width: 380px;

              &:first-child {
                min-width: 115px !important;
                width: 115px !important;
                position: -webkit-sticky;
                position: sticky;
                left: 0px;
                background: var(--bgSecondary);
              }
            }
          }
        }
        & > tbody {
          position: relative;

          & > tr {
            & > td {
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-transform: uppercase;
              padding: 12px;
              border-bottom: 1px solid var(--lineColor);
              min-width: 380px;
              width: 380px;

              &:first-child {
                min-width: 115px !important;
                width: 115px !important;
                position: -webkit-sticky;
                position: sticky;
                left: 0px;
                background: var(--bgPrimary);
              }

              .classDetails {
                font-weight: 600;
              }
            }

            &:last-child {
              & > td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}
