.reportExportFamilyDetails {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__search {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    &__fields {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 20px;

      & > div {
        grid-column: span 2 / span 2;
      }

      &--studentStatus {
        grid-column: span 6 / span 6 !important;

        & ~ div {
          grid-column: span 3 / span 3 !important;
        }
      }
    }

    & > .btn {
      margin-bottom: 12px;
    }
  }

  &__content {
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;
    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      &--actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 17px;
        & > .c__input-search {
          width: 286px;
        }
      }
    }

    &__table {
      display: block;
      & .app-table {
        margin-top: 24px;
        overflow-x: auto !important;
        &::-webkit-scrollbar {
          height: 5px !important;
        }

        & .table-header-item {
          min-width: 180px !important;
          max-width: 180px !important;
          width: 180px !important;
          white-space: initial !important;
          vertical-align: baseline;
        }
        & .pagination-table {
          position: sticky;
          left: 0;
        }
      }
    }
  }
}
