.level__milestoneCardContainer {
  &.error {
    .level__milestoneCard {
      border-color: var(--errorColor);
    }

    .level__milestoneCard__errorMessage {
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
      color: var(--errorColor);
      margin-top: 4px;
    }
  }

  .level__milestoneCard {
    position: relative;
    width: auto;
    height: auto;
    background: var(--bgTertiary);

    border: 1px solid transparent;
    border-radius: 8px;
    padding: 12px;

    &.isEdit {
      cursor: pointer;
    }

    &__remove {
      position: absolute;
      right: -6px;
      top: -6px;

      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      border: 1px solid var(--lineColor);
      background: var(--bgTertiary);
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);
      transition: all 0.3s;
      cursor: pointer;

      & > svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: var(--errorColor);
        border-color: var(--errorColor);
      }
    }

    &__name {
      color: var(--titleColor);
      background-color: transparent;
      resize: none;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      width: 100%;
      height: 58px;
    }
  }
}
