.healthQuestionnaireListPage {
  // ADD FORM
  .healthQuestionnaireAddContainer {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 11;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transform: all 0.3s;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    .healthQuestionnaireAddForm {
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 12;

      display: flex;
      flex-direction: column;

      min-width: calc(100vw - 280px);
      height: 100vh;
      background-color: var(--bgPrimary);
      border: 1px solid var(--lineColor);
      transition: all 0.3s;
      overflow: hidden;

      &.active {
        left: 280px;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 24px;
        border-bottom: 1px solid var(--lineColor);
        & > div {
          width: 24px;
          height: 24px;
          cursor: pointer;
          & > img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }
        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }
      }
      &__content {
        overflow-y: auto;
        flex: 1;
        padding: 24px 24px 12px 24px;

        &-wrapper {
          display: flex;
          flex-direction: column;
        }

        &-fields {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          align-items: center;
          column-gap: 24px;
          margin-top: 24px;
          & > div {
            grid-column: span 3 / span 3;
          }
          &-toggle {
            grid-column: span 1 / span 1 !important;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            padding: 20px 0;
            &-item {
              display: flex;
              align-items: center;
              column-gap: 8px;
              & > p {
                color: var(--titleColor);
                font-size: 14px;
                font-weight: 500;
                line-height: 140%;
                letter-spacing: 0.5px;
              }
            }
          }
        }
        &-area {
        }
        &-radio {
          margin-top: 20px;
          & > p {
            color: var(--titleColor);
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: 0.5px;
            margin-bottom: 8px;
          }
          &-group {
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            & .c__radio {
              text-transform: capitalize;
            }
          }
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 24px;
        border-top: 1px solid var(--lineColor);
        & > button {
          width: 120px;
          cursor: pointer;
        }
      }
    }
  }

  // MAIN || LIST
  .healthQuestionnaire {
    .healthQuestionnaire__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 8px;

          & svg {
            width: 22px;
            height: 22px;
          }

          .c__input-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;

            & p {
              color: var(--titleColor);
              font-size: 16px;
              padding-top: 2px;
              line-height: 16px;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }
      &-content {
        margin-top: 24px;
        .app-table {
          .buttonGroups {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            & div {
              padding: 3px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              & svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
