.student_route {
  .assessmentInStudentDetail__level {
    &__card {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
    }

    & svg {
      cursor: pointer;
    }
  }
}
