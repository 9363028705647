.classTemplatePage {
  // ADD FORM
  .classTemplateAddContainer {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 11;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transform: all 0.3s;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    .classTemplateAddForm {
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 12;

      display: flex;
      flex-direction: column;

      min-width: calc(100vw - 280px);
      height: 100vh;
      background-color: var(--bgPrimary);
      border: 1px solid var(--lineColor);
      transition: all 0.3s;
      overflow: hidden;

      &.active {
        left: 280px;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 24px;
        border-bottom: 1px solid var(--lineColor);

        & > div {
          width: 24px;
          height: 24px;
          cursor: pointer;
          & > img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }
        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }
      }

      & .c__card-header {
        margin-bottom: 16px;
      }

      &__content {
        overflow-y: auto;
        flex: 1;
        padding: 24px 24px 12px 24px;

        &-wrapper {
          display: flex;
          flex-direction: column;
        }

        &-template {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 24px;
        }

        &-duration {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 24px;
          .pick-color-container {
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            .pick-color {
              border-radius: 8px;
              height: 54px;
              margin-top: 20px;
            }
          }
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 24px;
        border-top: 1px solid var(--lineColor);
        & > button {
          width: 120px;
          cursor: pointer;
        }
      }
    }
  }

  // MAIN || LIST
  .classTemplate {
    .classTemplate__cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
    }

    .classTemplate__card {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 20px 20px 12px 20px;
      margin-bottom: 30px;

      &-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 10px;

        &-left {
          &-title {
            color: var(--titleColor);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            text-transform: uppercase;
            margin-bottom: 2px;
          }
          &-subtitle {
            color: var(--subTitleColor);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 16.8px */
          }
        }

        &-right {
          & svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      &-count {
        color: var(--titleColor);
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .classTemplate__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 8px;

          & svg {
            width: 22px;
            height: 22px;
          }

          .c__input-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            & .plusIcon {
              width: 22px;
              height: 22px;
              & > img {
                display: block;
                width: 100%;
                object-fit: contain;
              }
            }

            & p {
              color: var(--titleColor);
              font-size: 16px;
              padding-top: 2px;
              line-height: 16px;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }
      &-content {
        margin-top: 24px;
        .app-table {
          .buttonGroupsContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .buttonGroups {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 60px;
            column-gap: 8px;
            & div {
              padding: 3px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              & svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
