.reportMember {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__search {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    &__fields {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;

      & > div {
        padding-bottom: 0px;
      }
    }

    & > .btn {
      margin-bottom: 4px;
    }
  }

  // Table Summary View
  .reportMemberSummaryView {
    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      & > .c__input-search {
        width: 286px;
      }

      &--actions {
        display: flex;
        align-items: center;
        gap: 24px;

        .c__select {
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
    }

    &__table {
      display: flex;
      align-items: flex-start;
      justify-content: stretch;

      gap: 24px;

      overflow-x: auto !important;

      &::-webkit-scrollbar {
        height: 5px !important;
      }

      & > table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;

        & > thead {
          & > tr {
            & > th {
              vertical-align: baseline;
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              text-transform: uppercase;
              background: var(--bgSecondary);
              padding: 10px;
              text-align: left;
              min-width: 300px;
              width: 300px;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-transform: uppercase;
              padding: 12px;
              border-bottom: 1px solid var(--lineColor);
              min-width: 175px;
              width: 175px;
            }
            &:last-child {
              & > td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  // Table Detailed View
  .reportMemberDetailedView {
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }

      &--actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 17px;

        .c__select {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        & > .c__input-search {
          width: 286px;
        }
      }
    }

    &__table {
      display: block;

      .app-table {
        table {
          overflow-x: auto;
        }
      }

      & .app-table {
        margin-top: 24px;
      }
    }
  }
}
