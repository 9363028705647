.locationAddContainer {
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    z-index: 11;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: all 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .locationAddForm {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 12;

    display: flex;
    flex-direction: column;

    min-width: calc(100vw - 280px);
    height: 100vh;
    background-color: var(--bgPrimary);
    border: 1px solid var(--lineColor);
    transition: all 0.3s;
    overflow: hidden;

    &.active {
      left: 280px;
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 24px;
      border-bottom: 1px solid var(--lineColor);
      & > div {
        width: 24px;
        height: 24px;
        cursor: pointer;
        & > img {
          display: block;
          width: 100%;
          object-fit: contain;
        }
      }
      & > p {
        color: var(--titleColor);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 28px */
        text-transform: uppercase;
      }
    }

    &__content {
      overflow-y: auto;
      flex: 1;
      padding: 24px 24px 24px 24px;
      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      .locationAddForm__content-information {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 24px;

        .item {
          grid-column: span 1;
        }

        .item_custom {
          grid-column: span 3;
        }
      }

      .locationAddForm__content-personal_information {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        column-gap: 24px;

        .item {
          grid-column: span 2;
        }

        .item_custom {
          grid-column: span 3;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      column-gap: 24px;
      padding: 24px;
      border-top: 1px solid var(--lineColor);
      & > button {
        cursor: pointer;
      }
    }
  }
}
