.absenceModalWrapper {
  & .lessonDateAndTimes {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;

    & > p {
      color: var(--titleColor);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;
    }
    & > div {
      color: var(--titleColor);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;

      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid var(--lineColor);
    }
  }

  & .dateTime {
    display: flex;
    gap: 24px;
    margin-bottom: 12px;
    & > div {
      flex: 1;
    }
  }
  & .reason {
    & > .c__textarea {
      margin-bottom: 12px;
    }
  }

  & .declineReason {
    & > .c__textarea {
      margin-top: 24px;
    }
  }

  & .schedules {
    margin-top: 24px;

    & .app-table {
      min-height: unset;
    }
  }

  & .c__loading__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 0;
    & > .c__loading__container-main {
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .uploadTitle {
    color: var(--titleColor);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
  }

  & .c__modal-formActions {
    justify-content: space-between;
    & .commonBtnGroup {
      width: unset;
      display: flex;
      align-items: center;
      gap: 24px;
    }
    & .absenceBtnGroup {
      width: unset;
      display: flex;
      align-items: center;
      gap: 24px;
      & > .declineBtn {
        background-color: var(--errorColor);
        border-color: var(--errorColor);
      }
    }
    & .btn {
      min-width: 108px;
      width: unset;
    }
  }

  .tittle_wrapper {
    .tittle {
      display: flex;
      justify-content: space-between;

      .tt1 {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }

      .tt2 {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;

        text-transform: none;
      }
    }

    .timeLastSession {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;

      margin-top: 20px;
      text-transform: none;
    }
  }
}
