.location_route {
  .summary-info {
    padding: 30px 30px 0 30px;
    margin-top: 0px;

    .c__card {
      position: relative;

      .loadData {
        position: absolute;
        inset: 0;
        z-index: 1;

        opacity: 0.3;
        background-color: #000;

        display: flex;
        justify-content: center;
        align-items: center;

        & > span > span {
          background-color: var(--titleColor) !important
          ;
        }
      }
    }

    .summary-info_header {
      display: flex;
      justify-content: space-between;

      padding-bottom: 10px;

      .summary-info_name {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 39.2px */
        text-transform: uppercase;
      }

      .summary-info_button {
        display: flex;
        justify-content: flex-end;
        gap: 24px;

        div {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }
      }
    }

    .summary-info_wrap {
      display: flex;
      gap: 20px;
      text-transform: uppercase;
      font-size: 14px;
      justify-content: space-between;
      align-items: flex-end;
    }

    .summary-info_wrap-content {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .summary-info-status {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 10px;
      padding: 4px 6px 5px 6px;
      margin-right: 10px;

      & > div {
        font-size: 8px;
        line-height: 8px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .summary-info-item {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;

      .summary-info-item_label {
        color: var(--labelColor);
      }
    }
  }
}
