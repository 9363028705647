.c__otpContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
}
.c__otpInput {
  height: 94px;
  width: calc(440px / 6);
  background-color: transparent;
  border: 1px solid var(--lineColor);

  color: var(--primaryColor);
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 140%; /* 44.8px */

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

/* Chrome, Safari, Edge, Opera */
input.c__otpInput::-webkit-outer-spin-button,
input.c__otpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.c__otpInput[type='number'] {
  -moz-appearance: textfield;
}
