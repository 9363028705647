.level__milestoneCardAdd {
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  height: 100%;
  min-height: 84px;

  background: var(--bgPrimary);

  border-radius: 8px;
  border: 1px dashed var(--lineColor);
  // padding: 54px 57px;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    border-color: var(--primaryColor);
    & svg {
      fill: var(--primaryColor);
    }

    & p {
      color: var(--primaryColor);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;

    & > svg {
      transition: all 0.3s;
      display: block;
      width: 30px;
      height: 30px;
    }

    & > p {
      transition: all 0.3s;
      color: var(--titleColor);
      font-size: 12px;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      white-space: nowrap;
    }
  }
}
