.assessmentHistoryInClass {
  // MAIN || LIST
  .assessmentHistoryInClass__table {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      &-title {
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        text-transform: uppercase;
      }

      &-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;

        & svg {
          width: 22px;
          height: 22px;
        }

        .c__input-search {
          min-width: 478px;
        }

        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;

          & p {
            color: var(--titleColor);
            font-size: 16px;
            padding-top: 2px;
            line-height: 16px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
      }
    }
    &-content {
      margin-top: 24px;
      .app-table {
        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 12px;
        }
      }
    }
  }
}
