.c__app__ckEditor {
  .ck {
    background-color: var(--bgPrimary) !important;
    color: var(--titleColor) !important;
    border-color: var(--lineColor) !important;
  }
  .ck-editor {
  }
  .ck-focused {
    border-color: var(--lineColor) !important;
  }
  .ck-content {
    & ol,
    ul {
      padding-left: 20px !important;
    }
  }

  // Turn off ck balloon
  .ck.ck-balloon-panel.ck-powered-by-balloon {
    --ck-powered-by-background: var(--accented-background-color);
  }
  .ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by a::after {
    display: none;
  }
  .ck.ck-editor__editable.note-detail-editable-text-editor {
    padding-bottom: 10px;
  }
  .ck.ck-balloon-panel.ck-powered-by-balloon
    .ck.ck-powered-by
    .ck-powered-by__label {
    text-transform: none;
    font-family: var(--main-font-family);
    padding-left: 2px;
    color: var(--main-text-color);
  }
  .ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by .ck-icon {
    filter: brightness(10);
  }
  &.disabled {
    background-color: var(--bgTertiary);
    pointer-events: none;
    .ck {
      background-color: var(--bgTertiary) !important;
      color: var(--titleColor) !important;
      border-color: var(--lineColor) !important;
    }
  }
}
