.notification {
  position: relative;

  .pageLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    padding: 50px 30px;

    &-main {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      & > span > span {
        background-color: var(--primaryColor) !important;
      }
    }
  }

  &__initLoad {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .body__noResult {
    margin: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    .text {
      color: var(--subTitleColor);
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 24px;
    background: var(--bgPrimary);
    border: 1px solid var(--lineColor);
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .tittle {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;

      text-transform: uppercase;
      color: var(--tittleColor);
    }

    .subTittle {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      color: var(--infoColor);
    }

    .action {
      display: flex;
      align-items: center;
      gap: 8px;

      div {
        cursor: pointer;
      }

      &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--primaryColor);

        svg {
          fill: var(--primaryColor);
        }
      }

      .c__input-search,
      .c__select {
        height: 42px;
      }

      // fix date picker not overflow
      .MuiFormControl-root {
        position: static;
      }

      .c__input,
      .c__select,
      .c__datePicker_wrapper,
      .c__phoneNumberInput-wrapper {
        padding-bottom: 0px;
      }

      .c__select,
      .MuiStack-root,
      .c__phoneNumberInput-wrapper {
        padding-top: 0px;
      }

      .c__input:first-child {
        padding-top: 0px;
      }

      .c__select:first-child {
        max-width: 141px;
        padding: 0;

        .c__select-input {
          text-transform: none;
        }
      }
    }
  }

  &__body {
    overflow: auto;
    max-height: max(80vh, 300px);
    min-width: 10vh;

    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1px;
    border: 1px solid var(--lineColor);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    background: var(--bgPrimary);

    .statusNew {
      cursor: pointer;
      background: var(--bgTertiary);

      &__dot {
        margin-left: auto;
        border-radius: 100%;
        width: 8px;
        min-width: 8px;
        max-width: 8px;
        height: 8px;
        min-height: 8px;
        max-height: 8px;
        background: var(--primaryColor);
      }
    }

    .statusRead {
      background: rgb(27, 28, 36);
    }

    .body__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      padding: 24px;

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .avatar {
        width: 44px;
        min-width: 44px;
        max-width: 44px;
        height: 44px;
        min-height: 44px;
        max-height: 44px;

        .custom_avatar {
          overflow: hidden;
          border-radius: 100%;
          flex: none;
          background: #dde5ed;
          display: flex;
          align-items: center;
          justify-content: center;

          &-size-small {
            width: 28px;
            height: 28px;
          }

          &-size-medium {
            width: 36px;
            height: 36px;
          }

          &-size-large {
            width: 44px;
            height: 44px;
          }

          &-name {
            color: var(--primaryColor);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            text-transform: uppercase;
          }

          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .content_wrap {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .text2 {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: var(--infoColor);
          text-transform: uppercase;
        }

        .time2 {
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: var(--infoColor);
        }

        .body_content {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 12px;

          .tittle {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            text-transform: uppercase;
          }

          .tittle2 {
            margin-top: 2px;

            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            text-transform: uppercase;
            color: var(--infoColor);
          }

          .tag {
            background: var(--primaryColor);
            padding: 4px 6px;
            border-radius: 4px;

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--tittleColor);
            text-transform: uppercase;
          }

          .ct_tittle {
            margin-top: 2px;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            .tx {
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              text-transform: uppercase;
            }
          }

          .table {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            row-gap: 12px;
            background: var(--bgSecondary);
            padding: 12px;
            border-radius: 8px;

            .table_head {
              padding-left: 12px;

              font-size: 12px;
              font-weight: 500;
              line-height: 16.8px;
              text-transform: uppercase;
              color: var(--infoColor);
            }

            .table_body {
              padding: 12px;
              height: 100%;

              font-size: 14px;
              font-weight: 500;
              text-transform: uppercase;
              line-height: 19.6px;
            }

            .bd1 {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              border: 1px solid var(--lineColor);
              border-right: none;
            }

            .bd2 {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: 1px solid var(--lineColor);
              border-left: none;
            }
          }

          .columWrapper {
            display: flex;
            flex-direction: column;
          }

          .certificateWrapper {
            margin-top: 12px;

            display: flex;
            gap: 12px;

            .c__upload-documents {
              margin-top: 12px;
              &-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 12px;
              }
              &-item {
                position: relative;
                height: 115px;
                border-radius: 8px;
                border: 1px solid var(--lineColor);
                cursor: pointer;
                overflow: hidden;

                &-image {
                  width: 100%;
                  height: 100%;
                  & > img {
                    display: block;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                  }
                }

                &:hover .c__upload-documents-item-overlay {
                  display: flex;
                }

                &-overlay {
                  display: none;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.5);

                  align-items: center;
                  justify-content: center;
                  column-gap: 12px;
                  &-icon {
                    border-radius: 8px;
                    background: var(--bgPrimary);
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    padding: 4px;
                    & > svg {
                      width: 22px;
                      height: 22px;
                      display: block;
                      width: 100%;
                      object-fit: contain;
                    }

                    &:first-child:hover {
                      background-color: var(--primaryColor);
                    }
                    &:last-child:hover {
                      background-color: var(--errorColor);
                    }
                  }
                }

                .c__upload-documents-item-overlay-icon:last-child {
                  display: none;
                  cursor: none;
                }
              }
            }

            .no_certificate {
              display: flex;
              padding: 8px;
              align-items: center;
              gap: 12px;

              border-radius: 8px;
              border: 1px solid var(--lineColor);

              color: var(--infoColor);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
              text-transform: uppercase;
            }
          }

          .c__documents-slider {
            z-index: 60;
            // overflow: visible;
            overflow: hidden;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 62.5vw;
            height: 60vh;

            max-width: 900px;
            max-height: 600px;

            background-color: var(--bgPrimary);
            border-radius: 8px;
            border: 1px solid var(--lineColor);

            &-header {
              z-index: 10;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;

              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 72px;
              background: linear-gradient(
                180deg,
                var(--bgPrimary) 0%,
                rgba(28, 28, 36, 0) 100%
              );
              padding: 24px;
              & > p {
                color: var(--titleColor);
                font-size: 16px;
                font-weight: 500;
                line-height: 140%; /* 22.4px */
                letter-spacing: 0.3px;
                text-transform: uppercase;
              }
              & > div > svg {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
            }
            // override swiper
            .swiper {
              overflow: visible;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 78%;
              height: 100%;
              &::before {
                display: block;
                content: '';
                position: absolute;
                right: 100%;
                top: 0;
                width: 15%;
                height: 100%;
                background-color: var(--bgPrimary);
                z-index: 5;
              }
              &::after {
                display: block;
                content: '';
                position: absolute;
                left: 100%;
                top: 0;
                width: 15%;
                height: 100%;
                background-color: var(--bgPrimary);
                z-index: 5;
              }
            }
            .swiper-wrapper {
            }
            .swiper-button-prev {
              width: 40px;
              height: 40px;
              left: -52px;
              transform: translateX(-50%);
              background-color: rgba(0, 0, 0, 0.5);
              border-radius: 50%;
              color: white;
              &::after {
                font-size: 16px;
                font-weight: 700;
              }
            }
            .swiper-button-next {
              width: 40px;
              height: 40px;
              right: -52px;
              transform: translateX(50%);
              background-color: rgba(0, 0, 0, 0.5);
              border-radius: 50%;
              color: white;
              &::after {
                font-size: 16px;
                font-weight: 700;
              }
            }
            .swiper-slide {
              background-color: var(--bgPrimary);
              & > img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    .settings__body__content {
      display: grid;
      grid-template-columns:
        repeat(4, minmax(0, 1fr)) repeat(2, 30px) repeat(2, 85px)
        repeat(3, minmax(0, 1fr));
      justify-content: center;
      align-items: center;
      row-gap: 15px;

      padding: 0 24px 24px 24px;

      .fakeGap {
        border-bottom: 1px solid var(--lineColor);
        min-height: 65px;
        padding-bottom: 25px;
      }

      .item1 {
        grid-column: span 4;
        display: flex;
        align-items: center;

        padding-right: 20px;

        font-size: 14px;
        font-weight: 400;
        line-height: 12px;
      }

      .name_ct {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .text2 {
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          color: var(--infoColor);
        }

        .ct_form {
          display: flex;
          align-items: center;
          gap: 8px;

          .c__select {
            padding: 0px;
          }
        }
      }

      .ct_event {
        // pointer-events: none;
      }

      .item2 {
        grid-column: span 2;

        display: flex;
        align-items: center;
        justify-content: center;

        min-height: 30px;

        .c__checkbox {
          padding-left: 25px;
          user-select: auto;
        }
      }

      .item3 {
        grid-column: span 3;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
