.rollCallPage {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .class-title {
      font-size: 14px;
      padding: 12px;
      border-bottom: 4px solid var(--primaryColor);
      text-transform: uppercase;
      font-weight: 500;
    }
    .filter-btn {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
  .content {
    margin-top: 24px;
    display: grid;
    gap: 24px;
    background-color: var(--bgPrimary);
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    .header-table {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }
    &-table {
      width: 100%;
    }
  }
  .buttonGroups {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    .ellipsisVertical {
      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s;

      position: relative;
      padding: 0;
      border: none;

      & svg {
        width: 24px;
        height: 24px;
        padding: 3px;
        border-radius: 4px;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          background-color: var(--primaryColor);
        }
      }

      .viewDetailPopup {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;

        border-radius: 8px;
        border: 1px solid var(--lineColor);
        background: var(--bgPrimary);
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

        padding: 8px 0px;

        &-item {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          white-space: nowrap;
          padding: 10px 24px;
          cursor: pointer;
          text-transform: none;
        }

        &-horizontal {
          height: 1px;
          width: 100%;
          background-color: var(--lineColor);
          margin: 8px 0px;
        }
      }
    }
  }
  .filter-section {
    display: flex;
    align-items: baseline;
    .area-select {
      max-width: 200px;
      width: 20%;
      margin-left: 15px;
      .c__select-field-small {
        padding: 8px 12px;
        font-size: 12px;
        height: 35px;
      }
    }
  }
  .phone-field {
    display: flex;
    gap: 10px;
    a {
      text-decoration: none;
    }
  }
  .no-data {
    padding: 10px;
    text-align: center;
  }
  .footer-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.layoutContainer.rollCall {
  gap: 48px;
}
