.calendar {
  position: relative;
  padding: 30px;
  height: 100%;
  overflow: hidden;
  padding-bottom: 0;
}
.headerCalendarSetting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
}
.leftHeaderCalendar {
  display: flex;
  align-items: center;
}
button {
  background-color: transparent;
  margin: 0;
  cursor: pointer;
}
.btnToday {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  background-color: #1c1c24;
  margin-right: 24px;
}
.btnArrow {
  border: none;
  margin-right: 14px;
  cursor: pointer;
}
.monthLabel {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}
.rightHeaderCalendar {
  display: flex;
  align-items: center;
}
.btnHeaderLabel {
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  align-items: center;
}
.btnActionHeaderCalendar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 24px;
  padding: 10px 12px 10px 20px;
  height: 42px;
  background-color: #1c1c24;
}
.btnModeView {
  position: relative;
}
.modeViewOption {
  padding: 10px 20px;
  cursor: pointer;
}
button {
  display: block;
  border: 1px solid #333;
  padding: 10px 20px;
  border-radius: 8px;
}
.calendar-scroll {
  overflow-y: scroll;
  height: calc(100vh - 277px - 105px);
  width: calc(100% + 5px);
}
.calendar-scroll-day {
  overflow-y: scroll;
  height: calc(100vh - 220px - 105px);
  width: calc(100% + 5px);
  display: flex;
}
.dashboardHeader {
  display: flex;
  padding: 20px 30px 20px 0;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bgPrimary);
  border-bottom: 1px solid var(--lineColor);
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9;
  right: 0;
  width: 100%;
  height: 85px;
}
.dashboardHeaderCollapsed {
  display: flex;
  padding: 20px 30px 20px 30px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bgPrimary);
  border-bottom: 1px solid var(--lineColor);
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9;
  right: 0;
  width: calc(100% - 280px);
}
.dashboardHeaderText {
  color: var(--titleColor);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.5px;
  background: none;
}
.searchText {
  color: var(--titleColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  background: none;
  margin-left: 10px;
}
