.class-detail {
  width: 500px;
  // min-height: 590px;
  padding: 20px 20px 30px 20px;
  border-width: 2px;
  border-radius: 15px;
  background: var(--bgPrimary);
  z-index: 1;
  resize: vertical;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .color {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: var(--bgClass);
    }

    .header-left,
    .header-right {
      display: flex;
      align-items: center;
      .capacity {
        font-size: 14px;
        margin-left: 8px;
      }
    }

    .header-content {
      margin-left: 5px;
      text-transform: uppercase;
    }
  }

  .contact-detail {
    border-bottom: 1px solid var(--labelColor);
    padding-bottom: 20px;

    .contact-item {
      display: flex;
      margin-top: 12px;
      align-items: center;

      &-content {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  .student-list {
    padding-top: 20px;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      &-left {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      div {
        font-weight: 500;
      }
      .btn-add {
        width: 124px;
        display: flex;
        padding: 10px;
        align-items: center;
      }
    }

    .student-content {
      margin-top: 20px;
      max-height: 160px;
      overflow-y: scroll;
    }

    .tooltip-detail {
      .styles-module_arrow__K0L3T {
        width: 9px;
        height: 9px;
        border-right: none;
        border-bottom: none;
      }
    }
    .nameOfStudent {
      display: flex;
      align-items: center;
      gap: 8px;
      .tag {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: var(--primaryColor);
        font-size: 10px;
        font-weight: 500;
      }
    }
  }

  .table {
    text-align: left;
    width: 100%;
    border: none;

    .table-header {
      text-transform: uppercase;
      background-color: #000000;

      .table-header-item {
        padding: 10px;
      }
    }

    .table-content-item {
      border-bottom: 1px solid var(--labelColor);

      td {
        padding: 10px;
        border-bottom: 1px solid var(--labelColor);

        .student-name {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100px;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }

  .student-list-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;

    .text {
      color: var(--primaryColor);
      margin-right: 10px;
      font-weight: 500;
    }
  }
}

.content-tooltip-calendar-content {
  border: 1px solid var(--lineColor);
  border-radius: 15px;
  .content-tooltip-calendar-arrow path {
    color: var(--bgPrimary);
    stroke: var(--lineColor);
    stroke-width: 2px;
  }
}
