$animation-speed: 0.3s;

.search-container {
  position: relative;
  display: flex;
  padding: 10px 12px 10px 20px;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--lineColor);
  background: var(--bgPrimary);
  height: 42px;
}
.search-component {
  position: absolute;
  top: 44px;
  left: 0%;
  border-radius: 8px;
  background: var(--bgPrimary);
  border: 1px solid var(--lineColor);
  width: 100%;
  z-index: 12;
  transition: display 0.3s ease-in;
  height: 0;
  overflow: hidden;
  display: none;
}
.search-component.open {
  border: 1px solid var(--lineColor);
  transition: display 0.3s ease-out;
  padding: 24px;
  display: block;
  top: 44px;
  height: 373px;
}
.footer-search {
  display: flex;
  justify-content: flex-end;
}
.search-btn {
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 24px;
}
.date-search {
  display: flex;
  justify-content: space-between;
}

.c__textarea {
  position: relative;
  padding-top: 20px;
  padding-bottom: 12px;
}

.c__textarea-field {
  resize: vertical;
  min-height: 56.4px;
  box-sizing: border-box;

  width: 100%;
  outline: 0;
  border: 1px solid var(--lineColor);
  border-radius: 8px;

  font-size: 16px;
  color: var(--titleColor);
  font-weight: 400;
  line-height: 140%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;

  background: transparent;
  transition: border-color $animation-speed;

  padding: 16px 20px;
  height: auto;
}

.c__textarea-field::placeholder {
  color: transparent;
}

.c__textarea-label {
  transition: all $animation-speed;
}

.c__textarea-field:focus {
  border: 1px solid var(--primaryColor);
}

.c__textarea-field:placeholder-shown ~ .c__textarea-label {
  cursor: text;
  color: var(--placeholderColor);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  top: calc(16px + (22.4px / 2));
  left: 20px;
  transform: translateY(50%);
}

.c__textarea-field:not(:placeholder-shown) ~ .c__textarea-label-noFloating {
  display: none;
}

.c__textarea-label,
.c__textarea-field:focus ~ .c__textarea-label {
  position: absolute;
  top: 6px;
  transform: translateY(-50%);
  left: 0;
  display: block;

  color: var(--titleColor);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.5px;
  &-noFloating {
    display: none;
  }
}
