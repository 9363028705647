.locationPinDetails {
  display: flex;
  flex-direction: column;

  .areaInformation {
    .c__card {
      position: relative;

      .loadData {
        position: absolute;
        inset: 0;
        z-index: 1;

        opacity: 0.3;
        background-color: #000;

        display: flex;
        justify-content: center;
        align-items: center;

        & > span > span {
          background-color: var(--titleColor) !important
          ;
        }
      }
    }

    .card-content {
      display: grid;
      gap: 24px;

      .areaInformation-main-content {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .icon {
          width: 30px;
          height: 30px;
          padding: 3px;
          cursor: pointer;
          & svg {
            width: 24px;
            height: 24px;
            display: block;
            object-fit: contain;
          }
        }

        .content {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 24px;

          .item {
            grid-column: span 1;

            .c__input {
              padding-top: 0px;
              padding-bottom: 0px;
            }
          }

          .item_custom {
            grid-column: span 2;

            .c__textarea {
              padding-bottom: 0px;
            }
          }

          .padY {
            padding-bottom: 24px;
          }

          .padY2 {
            padding-top: 24px;
          }
        }
      }
    }
  }
}
