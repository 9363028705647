.instructorDetailPage {
  .personalInformation {
    margin-bottom: 24px;

    .cardContent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      & .customToggle {
        display: flex;
        align-items: center;
        column-gap: 12px;
        grid-column: span 4 / span 4;

        & > p {
          color: var(--titleColor);
          font-size: 16px;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
      }
      &.edit {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        & .customToggle {
          display: flex;
          align-items: center;
          column-gap: 12px;
          grid-column: span 3 / span 3;

          & > p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }
  }
  .contactDetails {
    margin-bottom: 24px;

    .cardContent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      .custom_show_phone {
        display: flex;
        justify-content: space-between;
        align-items: center;

        word-break: normal;

        gap: 12px;

        & > button {
          aspect-ratio: 1;
          padding: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          border: none;

          margin-right: auto;

          & > svg {
            min-width: 20px;
            min-height: 20px;
          }
        }
      }
    }
  }
  .icon {
    width: 30px;
    height: 30px;
    padding: 3px;
    cursor: pointer;
    & svg {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: contain;
    }
  }
  .about {
    margin-bottom: 24px;
  }
  .credentials {
    .c__card-header {
      &-title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      & .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;

        & p {
          color: var(--titleColor);
          font-size: 16px;
          padding-top: 2px;
          line-height: 16px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
        }
      }
    }
    &__table {
      & .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;

        & p {
          color: var(--titleColor);
          font-size: 16px;
          padding-top: 2px;
          line-height: 16px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
        }
      }

      .app-table {
        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & div {
            padding: 3px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            & svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      &-documents {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 6px;
      }
      &-document {
        width: 52px;
        height: 52px;
        border-radius: 8px;
        overflow: hidden;

        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        & > embed {
          width: 100%;
          height: 100%;
          max-height: 100%;
          overflow-y: hidden !important;
        }
      }
    }

    &-noResult {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
      }
    }
  }

  .instructorClassList {
    margin-top: 24px;
    & svg {
      cursor: pointer;
      flex: none;
    }

    &__filter {
      position: fixed;
      z-index: 30;
      top: 0;
      right: -380px;
      height: 100%;
      width: 380px;
      display: flex;
      flex-direction: column;
      border-left: 1px solid var(--lineColor);
      background: var(--bgPrimary);
      transition: all 0.3s;

      &.active {
        right: 0;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
        border-bottom: 1px solid var(--lineColor);
        padding: 16px 24px;
        & > svg {
          cursor: pointer;
        }
        &--title {
          flex: 1;
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          text-transform: uppercase;
        }
        &--reset {
          color: var(--primaryColor);
          text-align: right;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          cursor: pointer;
        }
      }
      &__content {
        flex: 1;
        height: 100%;
        padding: 24px;
      }
      &__actions {
        border-top: 1px solid var(--lineColor);
        padding: 20px 24px 24px 24px;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      border-radius: 8px;
      border: 1px solid var(--lineColor);
      background: var(--bgPrimary);
      padding: 24px;
    }
    &__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      &--search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        .c__input-search {
          min-width: 392px;
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 10px;
        }
      }
    }

    &__levels {
      width: 100%;

      .swiper-wrapper {
        align-items: flex-end;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &--item {
        color: var(--titleColor);
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;

        padding: 8px 6px;
        cursor: pointer;
        width: fit-content !important;
        white-space: nowrap;

        border-radius: 8px;
        border: 1px solid var(--lineColor);
        border-top: 3px solid var(--primaryColor);

        &.active {
          margin-bottom: 4px;
        }
      }
    }

    &__table {
      .content-cell {
        display: flex;
        align-items: center;
      }
      .buttonGroups {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        & div {
          padding: 3px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          & svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
