.c_appSubTabs {
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .swiper {
    margin-left: 0;
    margin-right: 0;

    .swiper-wrapper {
      display: flex;
      gap: 2px;

      .swiper-slide {
        width: fit-content;
        border: none;
        padding: 0;
        cursor: pointer;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.3px;
        text-transform: uppercase;

        &:last-child {
          // visibility: collapse;

          min-width: 200px;

          pointer-events: none;
        }

        &:hover {
          background-color: var(--bgPrimary) !important;
        }

        & > div {
          width: max-content;
          padding: 16px 12px;

          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        & > .tab-item {
          border: 1px solid var(--lineColor);
          border-radius: 8px 8px 0px 0px;
        }

        & > .inactive_tab {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          letter-spacing: 0.3px;
          text-transform: uppercase;

          p {
            color: var(--placeholderColor);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }

        & > .active_tab {
          border-left: 4px solid var(--primaryColor);

          color: var(--titleColor);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          letter-spacing: 0.3px;
          text-transform: uppercase;

          * {
            color: var(--titleColor);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
