.c__breadcrumb__wrapper {
  width: 100%;
  height: 84px;
  padding: 25px 30px 25px 20px;

  border-bottom: 1px solid var(--lineColor);
  background: var(--bgPrimary);

  display: flex;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 9;

  .c__breadcrumb {
    display: flex;
    align-items: center;
    column-gap: 20px;

    &-title {
      color: var(--titleColor);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.5px;
      height: 34px;
      text-decoration: none;
    }

    &-items {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 12px;

      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--lineColor);
      }

      & > a {
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-decoration: none;
      }
    }
  }
}
