.reportExportButton {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--lineColor);
  background: var(--bgPrimary);
  height: 42px;
  padding: 10px 12px;

  color: var(--titleColor);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
}
