.rollCallPage {
  .rollCall {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .rollCall__cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
    }

    .rollCall__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 8px;

          & svg {
            width: 22px;
            height: 22px;
          }

          .c__input-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            & .plusIcon {
              width: 22px;
              height: 22px;
              & > img {
                display: block;
                width: 100%;
                object-fit: contain;
              }
            }

            & p {
              color: var(--titleColor);
              font-size: 16px;
              padding-top: 2px;
              line-height: 16px;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }
      &-content {
        .app-table {
          .buttonGroupsContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .buttonGroups {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 60px;
            column-gap: 8px;
            & div {
              padding: 3px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              & svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }

  .summary-info {
    .card-content {
      display: flex;
      justify-content: space-between;
      .print-btn {
        display: flex;
        align-items: center;
      }
    }

    .summary-info_name {
      font-size: 28px;
      text-transform: uppercase;
      font-weight: 700;
    }

    .summary-info_wrap {
      display: flex;
      gap: 20px;
      text-transform: uppercase;
      font-size: 14px;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 10px;
    }

    .summary-info_wrap-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 4;
      gap: 24px;
    }

    .summary-info-status {
      font-size: 8px;
      background-color: var(--primaryColor);
      border-radius: 10px;
      display: inline-block;
      padding: 2px 10px;
      line-height: 2;
      margin-right: 10px;
    }

    .summary-info-item_label {
      color: var(--labelColor);
      font-size: 14px;
    }

    .summary-info_button {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
