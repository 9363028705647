.levelsBreakdownListPage {
  .levelTemplateAddFormContainer {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 20;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transform: all 0.3s;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    .levelTemplateAddForm {
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 21;

      display: flex;
      flex-direction: column;

      min-width: calc(100vw - 280px);
      height: 100vh;
      background-color: var(--bgPrimary);
      border: 1px solid var(--lineColor);
      transition: all 0.3s;
      overflow: hidden;

      &.active {
        left: 280px;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 24px;
        border-bottom: 1px solid var(--lineColor);
        & > div {
          width: 24px;
          height: 24px;
          cursor: pointer;
          & > img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }
        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }
      }
      &__content {
        overflow-y: auto;
        flex: 1;
        padding: 24px 24px 12px 24px;
        .c__card {
          row-gap: 48px;
        }

        &-wrapper {
          display: flex;
          column-gap: 48px;

          &-left {
            width: 65%;
          }
          &-right {
            width: 35%;
          }
        }

        &-levelWrapper {
          .c__card-header {
            margin-bottom: 16px;
          }
          .c__card-content {
            display: grid;
            align-items: flex-start;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 24px;
            row-gap: 8px;

            & > div {
              grid-column: span 3 / span 3;
            }

            .colorPickerWrapper {
              position: relative;
              align-self: flex-start;
              margin-top: 20px;

              .colorPicker {
                height: 54px;
                border-radius: 8px;
                cursor: pointer;
              }

              & > .pick-color {
                position: absolute;
                right: 0 !important;
                top: calc(100% + 12px);
                & svg {
                  cursor: pointer;
                }
              }
            }
          }
        }
        &__description {
          margin-top: 16px;
          &--label {
            color: var(--titleColor);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
            margin-bottom: 16px;
          }
          &--error {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;
            color: var(--errorColor);
          }
        }

        &-skillWrapper {
          margin-top: 32px;
          .c__card-header {
            margin-bottom: 12px;
          }

          &-addMore {
            color: var(--titleColor);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.3px;
            text-transform: uppercase;
            margin-top: 24px;
          }
        }
        &-imageWrapper {
          .c__card-header {
            margin-bottom: 12px;
          }
          &-images {
            display: flex;
            gap: 24px;
            margin-top: 16px;
            .c__uploadAvatar {
              border: 1px solid var(--lineColor);
              border-radius: 8px;
            }
            &-title {
              color: var(--titleColor);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            }
            &-error {
              color: var(--errorColor);
              font-size: 12px;
              font-style: normal;
              line-height: 140%; /* 19.6px */
            }
          }
        }
        &-nextLevelWrapper {
          .c__card-header {
            margin-bottom: 12px;
          }
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 24px;
        border-top: 1px solid var(--lineColor);
        & > button {
          width: 120px;
          cursor: pointer;
        }
      }
    }
  }

  // SKILLS AND MILESTONES
  .skills {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
