.booking {
  .classes-main {
    margin-top: 84px;
  }

  .classes-summary-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 24px;
  }

  .btnHeaderLabel {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    text-transform: capitalize;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .btnActionHeaderClass {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 42px;
  }
  .classes-table {
    border: 1px solid var(--labelColor);
    border-radius: 8px;
    padding: 20px;
    text-transform: uppercase;
    background-color: var(--bgPrimary);
    overflow-x: scroll;
    .classes-table_header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      gap: 24px;
      .header {
        font-weight: 500;
        font-size: 16px;
      }
      div button {
        padding: 10px 12px 10px 20px;
        white-space: nowrap;
      }
    }

    .classes-table_header_right {
      display: flex;
      gap: 10px;
    }

    .table_custom_btn {
      .btn-small {
        padding: 10px 20px !important;
        white-space: nowrap;
        width: 104px;
      }
      .btn-secondary.btn-small {
        padding: 6px 12px !important;
        white-space: nowrap;
        font-size: 14px;
        height: 32px;
      }
      .btn-primary.btn-small {
        padding: 6px 12px !important;
        white-space: nowrap;
        font-size: 14px;
        height: 32px;
        width: 104px;
        border: 1px solid var(--titleColor);
      }
    }
  }

  .content-cell {
    display: flex;
    align-items: center;
  }

  .buttonGroups {
    display: flex;
    align-items: center;
    column-gap: 12px;
    justify-content: flex-start;
    & div {
      cursor: pointer;
    }
  }
}

.day-daily {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  .day {
    margin: 10px 0;
    border-radius: 99px;
    padding: 5px;
    width: 30px;
    cursor: pointer;
  }
  & .active {
    background-color: var(--primaryColor);
    text-align: center;
  }
}
.recurrence-time {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}
.warning-conflict-tooltip {
  z-index: 2;
}
