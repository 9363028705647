$animation-speed: 0.3s;

.app-table {
  position: relative;

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 50px 30px;
    background: rgba(0, 0, 0, 0.5);
    &-main {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      & > span > span {
        background-color: var(--titleColor) !important;
      }
    }
  }

  .table-content-no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    width: 100%;
    padding: 24px;
  }

  .content-table {
    text-align: left;
    width: 100%;
    border: none;
    font-size: 14px;

    .table-header {
      text-transform: uppercase;
      background-color: #000000;

      .table-header-item {
        padding: 10px;
        font-size: 14px;
        white-space: nowrap;
        span,
        div {
          font-weight: 500;
        }
      }
    }

    .table-content-item {
      border-bottom: 1px solid var(--labelColor);
      text-transform: uppercase;

      &.active {
        background-color: var(--bgTertiary);
      }

      td {
        font-size: 14px;
        padding: 10px;
        border-bottom: 1px solid var(--labelColor);

        .student-name {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100px;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }

  .table-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 12px;

    button {
      padding: 1px 5px;
      margin-left: 5px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 28px;
      height: 28px;
    }

    .current-page {
      margin-right: 5px;
    }
  }

  .results-per-page {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .c__select .c__select-field-small {
      padding: 5px 16px;
      input {
        font-size: 12px;
      }
    }
  }

  .select-per-page-table {
    width: 100px;
  }

  .pagination-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}
