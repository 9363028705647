$animation-speed: 0.3s;

.c__input {
  position: relative;
  padding-top: 20px;
  padding-bottom: 12px;
}

.c__input-field {
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  border: 1px solid var(--lineColor);
  border-radius: 8px;

  font-size: 16px;
  color: var(--titleColor);
  font-weight: 400;
  line-height: 140%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;

  background: transparent;
  transition: border-color $animation-speed;

  &-large {
    height: 54px;
    padding: 16px 20px;
  }
  &-small {
    height: 42px;
    padding: 10px 20px;
  }

  &-append-right {
    padding-right: 36px;
  }
  &-disabled {
    background: var(--bgTertiary);
  }
}
.c__input-field.c__input-border {
  border: 1px solid var(--lineColor);
  &-error {
    border-color: var(--errorColor) !important;
  }
  &-success {
    border-color: var(--successColor) !important;
  }
  &-caption {
    border-color: var(--titleColor) !important;
  }
}

.c__input-field::placeholder {
  color: transparent;
}

.c__input-label {
  transition: all $animation-speed;
}

.c__input-field:focus {
  border: 1px solid var(--primaryColor);
}

.c__input-field:placeholder-shown ~ .c__input-label {
  cursor: text;
  color: var(--placeholderColor);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.c__input-field:not(:placeholder-shown) ~ .c__input-label-noFloating {
  display: none;
}

.c__input-label,
.c__input-field:focus ~ .c__input-label {
  position: absolute;
  top: 6px;
  transform: translateY(-140%);
  left: 0;
  display: block;

  color: var(--titleColor);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.5px;
  &-noFloating {
    display: none;
  }
}

.c__input-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 22px;
  height: 22px;
  transform: translateY(-50%);

  & svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
}

.c__input-message {
  margin-top: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 140%;

  &-error {
    color: var(--errorColor);
  }
  &-success {
    color: var(--successColor);
  }
  &-caption {
    color: var(--titleColor);
  }
}
