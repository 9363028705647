.student {
  position: relative;

  .pageLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    padding: 50px 30px;

    &-main {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      & > span > span {
        background-color: var(--primaryColor) !important;
      }
    }
  }

  .student-summary-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 20px;
  }

  .student-table {
    border: 1px solid var(--labelColor);
    border-radius: 8px;
    // overflow: hidden;
    padding: 20px;

    .student-table_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .student-table_header_right {
      display: flex;
    }
  }

  .personal-information {
    margin-bottom: 24px;

    .card-content {
      display: grid;
      gap: 24px;

      .personal-information-main-content {
        display: flex;
        gap: 30px;

        .student-avatar {
          flex: 1;
          img {
            width: 100%;
            border-radius: 4px;
            border: 1px solid var(--labelColor);
          }
        }

        .info-right {
          flex: 4;

          .content-info-right {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin-top: 20px;
            gap: 20px;
          }

          .c__card-content-item {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .student__table {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      &-title {
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        text-transform: uppercase;
      }

      &-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 18px;

        .c__input-search {
          min-width: 478px;
        }

        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & > .plusIcon {
            width: 22px;
            max-width: 22px;
            height: 22px;

            display: flex;
            align-items: center;
            justify-content: center;

            & > img {
              display: block;
              width: 100%;
              object-fit: contain;
            }
          }

          & > p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            padding-top: 2px;
            line-height: 14px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
      }
    }
    &-content {
      .app-table {
        .buttonGroups {
          display: flex;
          align-items: center;
          column-gap: 12px;
          & div {
            cursor: pointer;
          }
        }
      }
    }
  }
  .student-name {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-items: center;
      gap: 4px;

      svg {
        cursor: pointer;
        path {
          stroke: var(--errorColor);
          color: var(--errorColor);
        }
      }
      .tooltip-trigger {
        height: 20px;
      }
    }
    &-title {
      color: var(--errorColor);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
    &-text {
      color: var(--errorColor);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
