.classPage {
  .class {
    .class__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 18px;

          .c__select-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            & .plusIcon {
              width: 22px;
              height: 22px;
              & > img {
                display: block;
                width: 100%;
                object-fit: contain;
              }
            }

            & p {
              color: var(--titleColor);
              font-size: 16px;
              padding-top: 2px;
              line-height: 16px;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }
      &-content {
        margin-top: 24px;
        .app-table {
          .buttonGroups {
            display: flex;
            align-items: center;
            column-gap: 12px;
            & div {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .classAddContainer {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 11;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transform: all 0.3s;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    .classAddForm {
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 12;

      display: flex;
      flex-direction: column;

      min-width: calc(100vw - 280px);
      height: 100vh;
      background-color: var(--bgPrimary);
      border: 1px solid var(--lineColor);
      transition: all 0.3s;
      overflow: hidden;

      &.active {
        left: 280px;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 24px;
        border-bottom: 1px solid var(--lineColor);
        & > div {
          width: 24px;
          height: 24px;
          cursor: pointer;
          & > img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }
        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }
      }
      &__content {
        overflow-y: auto;
        flex: 1;
        padding: 24px 24px 12px 24px;

        &-wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 48px;
        }
        &-wrapper-template {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }

        &-wrapper-status {
          display: flex;
          gap: 24px;
        }

        &-template {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 24px;
          .pick-color-container {
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            .pick-color {
              border-radius: 8px;
              height: 54px;
              margin-top: 20px;
            }
          }
        }
        &-info {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 24px;
          .information {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 12px;
            align-items: flex-start;
            .c__input .c__input-message-error {
              white-space: nowrap;
            }
          }
        }
        .class-time {
          grid-column: span 2;
          .class-information {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 24px;
          }
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 24px;
        border-top: 1px solid var(--lineColor);
        & > button {
          cursor: pointer;
        }
      }
      .class-conflict {
        border-radius: 8px;
        // margin-top: 10px;
        &__title {
          font-weight: 500;
        }
        &__time {
          font-weight: 400;
        }
        &-description {
          font-size: 14px;
          cursor: pointer;
          width: 300px;
        }
      }
    }
  }
}

.classes {
  .classes-main {
    padding: 20px 30px 110px 30px;
  }
  .btnHeaderLabel {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    text-transform: capitalize;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .btnActionHeaderClass {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 42px;
  }
  .classes-summary-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 24px;
  }

  .classes-table {
    border: 1px solid var(--labelColor);
    border-radius: 8px;
    padding: 20px;
    text-transform: uppercase;
    background-color: var(--bgPrimary);
    font-weight: 500;

    & > div:last-child {
      overflow-x: auto;
    }

    .classes-table_header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;
      div {
        font-weight: 500;
        button {
          padding: 10px 12px 10px 20px;
          white-space: nowrap;
        }
      }
    }

    .classes-table_header_right {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .table_custom_btn {
      min-width: 100px;

      .btn-small {
        width: 100px;
        padding: 10px 20px !important;
        white-space: nowrap;

        border: 1px solid white;
      }
      .btn-secondary.btn-small {
        padding: 6px 12px !important;
        white-space: nowrap;
        font-size: 14px;
        height: 32px;
      }
      .btn-primary.btn-small {
        padding: 6px 12px !important;
        white-space: nowrap;
        font-size: 14px;
        height: 32px;
      }
    }
  }

  .content-cell {
    display: flex;
    align-items: center;
  }

  .buttonGroups {
    display: flex;
    align-items: center;
    column-gap: 12px;
    justify-content: flex-start;
    & div {
      cursor: pointer;
    }
  }
}

.day-daily {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  .day {
    margin: 10px 0;
    border-radius: 99px;
    padding: 5px;
    width: 30px;
    cursor: pointer;
  }
  & .active {
    background-color: var(--primaryColor);
    text-align: center;
  }
}
.recurrence-time {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}
.warning-conflict-tooltip {
  z-index: 2;
  svg {
    stroke: var(--warningColor);
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}
