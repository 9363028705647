.reportGst {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__search {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    &__fields {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;

      & > div {
        padding-bottom: 0px;
      }
    }

    & > .btn {
      margin-bottom: 4px;
    }
  }

  // Table Detailed View
  .reportLocationsDetailedView {
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }

      &--actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 17px;

        .c__select {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        & > .c__input-search {
          width: 286px;
        }
      }
    }

    &__table {
      display: block;

      .app-table {
        table {
          overflow-x: auto;
        }
      }

      & .app-table {
        margin-top: 24px;
      }
    }
  }
}
