/* The container */

$size: 20px;
$gap: 12px;
$fontSize: 16px;

.c__checkbox {
  display: block;
  position: relative;
  padding-left: calc($size + $gap);
  cursor: pointer;

  color: var(--titleColor);
  font-size: $fontSize;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default radio button */
  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Create a custom radio button */
  &-checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: $size;
    width: $size;
    background-color: var(--bgPrimary);
    border-radius: 4px;
    border: 1px solid var(--lineColor);
    &-noLabel {
      transform: translateY(-50%);
    }
  }
}

/* On mouse-over, add a grey background color */
.c__checkbox:hover .c__checkbox-input ~ .c__checkbox-checkMark {
  background-color: var(--bgPrimary);
}

/* When the radio button is checked, add a blue background */
.c__checkbox .c__checkbox-input:checked ~ .c__checkbox-checkMark {
  background-color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.c__checkbox-checkMark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the check mark when checked */
.c__checkbox input:checked ~ .c__checkbox-checkMark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.c__checkbox .c__checkbox-checkMark:after {
  top: 50%;
  left: 50%;
  width: 4px;
  height: 9px;
  border: solid var(--titleColor);
  border-width: 0 2px 2px 0;
  -webkit-transform: translate(-50%, -60%) rotate(45deg);
  -ms-transform: translate(-50%, -60%) rotate(45deg);
  transform: translate(-50%, -60%) rotate(45deg);
}
