.c__uploadAvatar {
  .c__file-uploadAvatar {
    position: relative;
    max-width: 100%;
    height: 240px;
    width: 240px;
    text-align: center;

    &-drag-element {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    &-input {
      display: none;
    }

    &-label {
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      border-radius: 8px;
      border: 1px solid var(--lineColor);
      transition: all 0.3s;

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 5px;

        &-image {
          width: 30px;
          height: 30px;
          opacity: 0.5;
          & > img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }

        & > p {
          color: var(--subTitleColor);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 16.8px */
        }
      }

      &.drag-active {
        opacity: 0.6;
        border-color: var(--titleColor);
      }
    }

    &-display {
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      transition: all 0.3s;
      overflow: hidden;
      cursor: pointer;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        .c__file-uploadAvatar-display-overlay {
          opacity: 1;
          visibility: visible;
        }
      }

      &-overlay {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);

        & > button {
          height: 32px !important;
          max-width: 65%;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 4px;
          & > img {
            width: 16px;
            height: 16px;
          }
          & > p {
            color: var(--titleColor);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
            white-space: nowrap;
          }
        }
      }

      &.drag-active {
        opacity: 0.6;
        border-color: var(--titleColor);
      }
    }
  }
}
