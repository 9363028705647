.bulkManagementNotificationList {
  &__table {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }

      &--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
        .c__select{
          padding: 0;
        }
        & svg {
          width: 22px;
          height: 22px;
        }
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
      }
    }
    &__content {
      margin-top: 24px;
      .app-table {
        & .table-header-item:first-child {
          width: max-content !important;
        }
        & .table-header-item:last-child {
          width: 10% !important;
        }

        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 12px;
          & div {
            padding: 3px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            & svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        .notification-status {
          width: fit-content;
          display: flex;
          padding: 4px 6px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 9px;
          background: transparent;

          color: var(--titleColor);
          font-size: 8px;
          font-weight: 500;
          text-transform: uppercase;
          &-scheduled,
          &-delivered {
            background-color: var(--primaryColor);
          }
          &-sent {
            background-color: var(--warningColor);
          }
          &-canceled {
            background-color: var(--errorColor);
          }
        }
      }
    }
  }
}
