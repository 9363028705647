.class-content-detail {
  background: var(--bgSecondary);

  .class-tab {
    padding: 30px 30px 60px 30px;
  }

  .tab-class-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .summary-info {
    padding: 30px 30px 0 30px;
    margin-top: 0px;

    .summary-info_name {
      font-size: 28px;
      text-transform: uppercase;
      font-weight: 700;
      padding-bottom: 12px;
    }

    .summary-info_wrap {
      display: flex;
      gap: 20px;
      text-transform: uppercase;
      font-size: 14px;
      justify-content: space-between;
      align-items: flex-end;
    }

    .summary-info_wrap-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 4;
      gap: 12px;
      flex-wrap: wrap;
    }

    .summary-info-status {
      font-size: 8px;
      border-radius: 10px;
      display: inline-block;
      padding: 2px 10px;
      line-height: 2;
      margin-right: 10px;
      font-weight: 500;
      &-active {
        background-color: var(--primaryColor);
      }
      &-inactive {
        background-color: var(--errorColor);
      }
    }

    .summary-info-item_label {
      color: var(--labelColor);
      font-size: 14px;
    }

    .summary-info-item {
      margin-right: 20px;
      white-space: nowrap;
    }

    .summary-info_button {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      gap: 10px;
      white-space: nowrap;

      button:last-child {
        padding: 10px;
      }
    }
  }
}
