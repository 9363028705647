.sidebar {
  width: 100%;
  height: 100vh;
  border-right: 1px solid var(--lineColor);
  max-width: 280px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #1c1c24;
  z-index: 10;
}

.sidebar.collapsed {
  width: 0px;
  height: 92vh;
  transition: transform 0.2s;
  transition-timing-function: ease-in;
  transform: translateX(-280px) translateY(85px);
}
.sidebar.hover {
  width: 100%;
  height: 92vh;
  min-width: 280px;
  transition: transform 0.1s;
  transition-timing-function: ease-out;
  transform: translateY(85px) translateX(0px);
  position: fixed;
}
.sidebar.open {
  width: 100%;
  height: 100vh;
  min-width: 280px;
  transition: transform 0.3s;
  transition-timing-function: ease-out;
  transform: translateY(0px);
  position: fixed;
}
.sidebar button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ccc;
  border: none;
  outline: none;
  cursor: pointer;
}
.sidebar button:hover {
  background-color: #aaa;
}

.locations {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  margin: 0 24px;

  min-height: 40.8px;

  font-weight: 700;
  font-size: 18px;
  background-color: var(--bgTertiary);
}
select:focus-visible {
  outline: none;
}
.menu {
  margin-top: 24px;
  overflow-y: scroll;
  height: calc(100vh - 304px);
}
.menu.sidebar-hover {
  margin-top: 24px;
  overflow-y: scroll;
  height: calc(100vh - 388px);
}
.sidebar-item {
  display: block;
  text-decoration: none;
}

.sidebar_tittle_content {
  width: 100%;
  padding: 16px 16px 16px 24px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--subTitleColor);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  gap: 8px;
}

.sidebar-content {
  background: var(--bgTertiary);
  .sidebar-item {
    padding-left: 16px;
    display: block;
  }
}
.sidebar-item.active {
  border-right: 6px solid #034ea2;
}
.sidebar-item.active.open {
  border-right: none;
}

.sidebar-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--subTitleColor);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;

  & svg {
    width: 16px;
    height: 16px;
    stroke: var(--subTitleColor);
  }
}

.sidebar-title:hover {
  color: var(--titleColor);
}

.sidebar-title.active {
  .sidebar_tittle_content {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--titleColor);
    & svg {
      stroke: var(--titleColor);
    }
  }
}
.sidebar-title.open {
  .sidebar_tittle_content {
    & svg {
      transform: rotate(180deg);
    }
  }
}
.sidebar-content {
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: var(--titleColor);
  text-decoration: none;
}
.info-box {
  padding: 24px;
  border-bottom: 1px solid;
}
.footer-box {
  padding: 15px 24px;
}
.info-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  flex: none;
  display: flex;
  background: var(--whiteColor);
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  min-height: 38px;
  max-height: 38px;
  flex-shrink: 0;
  border-radius: 100%;

  position: relative;

  border: 4px solid var(--whiteColor);

  &__bgAdd {
    border: 4px solid #f2f6fa;

    background: #f2f6fa;
  }

  .showName {
    position: absolute;

    transition: all 0.15s ease-in-out;

    &--close {
      transform: translateY(-100%);
      // bottom: 100%;
      opacity: 0;
      pointer-events: none;
    }

    &--open {
      opacity: 1;
      transform: translateY(-45px);
    }

    width: max-content;

    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    background: var(--primaryColor);
    border: none;
    outline: none;

    color: var(--whiteColor);
    font-size: 12px;
    font-weight: normal;
    line-height: 10px;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      bottom: -15px;
      left: 10px;

      border-top: 8px solid var(--primaryColor);
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;

      outline: none;
    }
  }

  .avatarTxt {
    background: var(--lightBlue10Color);
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;

    width: 100%;
    height: 100%;

    color: var(--lightBlueColor);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  img {
    object-fit: contain;
    background: #feecf0;
    border-radius: 100%;

    width: 100%;
    height: 100%;
  }
}

.footer-text {
  color: var(--subTitleColor);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 16px */
}
.menu-icon {
  position: absolute;
  right: -15px;
  margin-top: 28px;
  padding: 6px;
  border-radius: 99px;
  background: #40404a;
  width: 18px;
  height: 18px;
  box-sizing: content-box;
  cursor: pointer;
  & svg {
    width: 18px;
    height: 18px;
  }
}
.menu-icon-collapsed {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  padding: 6px;
  width: 30px;
  height: 30px;
}
.menu-icon-collapsed:hover {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #40404a;
  border-radius: 99px;
  height: 509px;
}

.notifications__ellipsisVertical {
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;

  position: relative;
  padding: 0;
  border: none;
  padding: 6px;
  border-radius: 100%;

  background-color: transparent;

  div:first-child {
    cursor: pointer;
  }

  &:hover {
    fill: var(--whiteColor);
    background: var(--bgInputColor);

    svg {
      fill: var(--whiteColor);
    }
  }

  svg {
    position: relative;
  }

  .notification--counter {
    position: absolute;
    left: 50%;
    top: -25%;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--errorColor);

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--whiteColor);
    font-size: 9px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;

    cursor: pointer;
  }

  .viewDetailPopper {
    position: absolute;
    bottom: 100%;
    left: 100%;
    z-index: 10;

    border-radius: 8px;
    // border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

    min-width: 500px;

    .notification {
      max-height: 60vh;

      svg {
        fill: var(--primaryColor);
      }

      &__body {
        overflow: auto;
        min-height: 200px;
        max-height: 50vh;
      }
    }

    &-horizontal {
      height: 1px;
      width: 100%;
      background-color: var(--lineColor);
      margin: 8px 0px;
    }

    &-checkIn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      padding: 10px 24px;
      & > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
