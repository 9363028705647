.responsible_person_route {
  .responsible_person_student_session {
    & > .student {
      margin-top: 0;

      & > .layoutContainer {
        background: transparent;
        width: 100%;
        padding: 0 !important;
        margin-top: 0;

        & > .student {
          margin-top: 0;
        }
      }
    }
  }
}
