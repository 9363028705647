.staffAuditReportPage {
  & .c__modal-content {
    padding-bottom: 4px;
  }

  & .c__modal-actions {
    justify-content: center;
    & button {
      max-width: 180px;
    }
  }

  & .c__textarea-field {
    text-align: initial;
  }

  .staffAuditReport {
    &__generate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-height: 90px;
      margin-bottom: 30px;

      & > p {
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
      &-left {
        display: flex;
        align-items: center;
        column-gap: 24px;
      }
      &-right {
      }
    }
    &__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 18px;

          .c__input-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            & .downloadDocumentIcon {
              width: 22px;
              height: 22px;
              & > img {
                display: block;
                width: 100%;
                object-fit: contain;
              }
            }

            & p {
              color: var(--titleColor);
              font-size: 16px;
              font-weight: 500;
              line-height: 140%; /* 22.4px */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }
      &-content {
        margin-top: 24px;
        .app-table {
          .buttonGroups {
            display: flex;
            align-items: center;
            column-gap: 12px;
            & div {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
