.classDetailTabAbsenceForm {
  border: 1px solid var(--lineColor);
  border-radius: 0px 8px 8px 8px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  width: 100%;

  .approveIcon {
    width: 92px;
    height: 92px;
    display: flex;
    margin: 38px auto 14px;
  }

  .c__modal-content {
    & > .c__textarea {
      margin-top: 24px;
      & * {
        text-transform: initial;
        text-align: left;
      }
    }
  }

  .absentForm__table {
    width: 100%;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      &-title {
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        text-transform: uppercase;
      }
    }
    &-content {
      margin-top: 24px;

      .app-table {
        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 12px;
        }
      }
    }

    .classGroup {
    }
    .classItem {
      display: flex;
      align-items: center;
      column-gap: 4px;
      border-bottom: 1px solid var(--lineColor);
      padding: 8px 2px;
      &:last-child {
        border-bottom-color: transparent;
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
    }

    .documentGroup {
      display: flex;
      gap: 6px;
      flex-wrap: wrap;
    }
    .documentItem {
      width: 52px;
      height: 52px;
      border-radius: 8px;
      overflow: hidden;
      & > img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .enrollmentAbsenceForm__ellipsisVertical {
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s;

    position: relative;
    padding: 0;
    border: none;

    & svg {
      width: 24px;
      height: 24px;
      padding: 3px;
      border-radius: 4px;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        background-color: var(--primaryColor);
      }
    }

    .viewDetailPopper {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 10;

      border-radius: 8px;
      border: 1px solid var(--lineColor);
      background: var(--bgPrimary);
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

      padding: 8px 0px;

      &-item {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
        padding: 10px 24px;
        cursor: pointer;
        text-transform: capitalize;
      }

      &-horizontal {
        height: 1px;
        width: 100%;
        background-color: var(--lineColor);
        margin: 8px 0px;
      }

      &-checkIn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        padding: 10px 24px;
        & > p {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
}
