.c__timePicker_wrapper * {
  font-family: 'Inter', sans-serif !important;
}

.c__timePicker_wrapper {
  padding-bottom: 12px;
}
.c__timePicker_wrapper-noFloatingLabel {
  & .MuiFormLabel-root.MuiInputLabel-shrink {
    display: none !important;
  }
}

.c__timePicker_wrapper-border {
  &-error {
    & .MuiFormControl-root {
      border-color: var(--errorColor) !important;
    }
  }
  &-success {
    & .MuiFormControl-root {
      border-color: var(--successColor) !important;
    }
  }
  &-caption {
    & .MuiFormControl-root {
      border-color: var(--titleColor) !important;
    }
  }
}

.c__timePicker_wrapper.c__timePicker_wrapper-large,
.c__timePicker_wrapper.c__timePicker_wrapper-small {
  position: relative;

  &.disabled {
    .MuiFormControl-root {
      background-color: var(--bgTertiary) !important;
      cursor: default;
    }
  }

  .MuiStack-root {
    width: 100%;
    padding-top: 20px;
    .MuiTextField-root {
      min-width: 155px;
    }

    .MuiFormControl-root {
      width: 100%;
      outline: 0 !important;
      border: 1px solid var(--lineColor);
      border-radius: 8px;

      font-size: 16px;
      color: var(--titleColor);
      font-weight: 400;
      line-height: 140%;

      background: transparent;

      &:focus {
        border: 1px solid var(--primaryColor) !important;
      }

      // Label
      .MuiFormLabel-root {
        color: var(--placeholderColor);
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        transition: all 0.3s;
        &.MuiInputLabel-shrink {
          color: var(--titleColor) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          line-height: 140% !important; /* 19.6px */
          letter-spacing: 0.5px !important;
          transform: translateY(-100%);
          top: 0%;
        }
      }

      .MuiInputBase-root {
        box-sizing: border-box;
        border: none !important;
        outline: none !important;
        height: 100%;
        min-width: 150px;
        &.Mui-focused {
          & fieldset {
            border: none;
            outline: none;
          }
        }
        & > input {
          height: 100%;
          color: var(--titleColor) !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          padding: 0 0 0 20px;
          &.Mui-disabled {
            -webkit-text-fill-color: unset;
          }
        }

        .MuiInputAdornment-root {
          .MuiButtonBase-root {
            & > svg {
            }
          }
        }

        & > fieldset {
          border: none;
          outline: none;
          & > legend {
            & > span {
              display: block;
              color: var(--titleColor);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
  .c__timePicker-message {
    margin-top: 4px;

    font-size: 12px;
    font-weight: 400;
    line-height: 140%;

    &-error {
      color: var(--errorColor);
    }
    &-success {
      color: var(--successColor);
    }
    &-caption {
      color: var(--titleColor);
    }
  }
}

.c__timePicker_wrapper-large {
  .MuiFormControl-root {
    height: 54px;
  }
}
.c__timePicker_wrapper-small {
  .MuiFormControl-root {
    height: 42px;
    .MuiFormLabel-root {
      top: -7px;
    }
  }
}
.MuiPopper-root {
  z-index: 100 !important;
}
.c__timePicker_wrapper-x-small {
  .MuiFormControl-root {
    height: 42px;
    .MuiFormLabel-root {
      top: -7px;
    }
  }

  &.disabled {
    .MuiFormControl-root {
      background-color: var(--bgTertiary) !important;
      cursor: default;
    }
  }

  .MuiStack-root {
    width: 100%;
    padding-top: 20px;
    .MuiTextField-root {
      min-width: 90px;
    }

    .MuiFormControl-root {
      width: 100%;
      outline: 0 !important;
      border: 1px solid var(--lineColor);
      border-radius: 8px;

      font-size: 16px;
      color: var(--titleColor);
      font-weight: 400;
      line-height: 140%;

      background: transparent;

      &:focus {
        border: 1px solid var(--primaryColor) !important;
      }

      // Label
      .MuiFormLabel-root {
        color: var(--placeholderColor);
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        transition: all 0.3s;
        &.MuiInputLabel-shrink {
          color: var(--titleColor) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          line-height: 140% !important; /* 19.6px */
          letter-spacing: 0.5px !important;
          transform: translateY(-100%);
          top: 0%;
        }
      }

      .MuiInputBase-root {
        box-sizing: border-box;
        border: none !important;
        outline: none !important;
        height: 100%;
        width: 140px;
        &.Mui-focused {
          & fieldset {
            border: none;
            outline: none;
          }
        }
        & > input {
          height: 100%;
          color: var(--titleColor) !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          padding: 0 0 0 10px;
          width: 80px;
          &.Mui-disabled {
            -webkit-text-fill-color: unset;
          }
        }
        .MuiIconButton-root{
          padding-left: 0;
        }

        .MuiInputAdornment-root {
          .MuiButtonBase-root {
            & > svg {
            }
          }
        }

        & > fieldset {
          border: none;
          outline: none;
          & > legend {
            & > span {
              display: block;
              color: var(--titleColor);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
  .c__timePicker-message {
    margin-top: 4px;

    font-size: 12px;
    font-weight: 400;
    line-height: 140%;

    &-error {
      color: var(--errorColor);
    }
    &-success {
      color: var(--successColor);
    }
    &-caption {
      color: var(--titleColor);
    }
  }
}
