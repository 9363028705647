.c__app_header__wrapper {
  width: 100%;
  height: 84px;
  padding: 0 30px 0 20px;

  border-bottom: 1px solid var(--lineColor);
  background: var(--bgPrimary);

  display: grid;
  grid-template-columns: auto 1fr auto;
  overflow-x: auto;

  position: sticky;
  top: 0;
  z-index: 9;

  .c__app_header_cover {
    display: flex;
    align-items: center;

    .c__app_header {
      display: flex;
      align-items: center;
      column-gap: 20px;

      &-title {
        color: var(--titleColor);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.5px;
        height: 34px;
        text-decoration: none;
      }

      &-items {
        display: flex;
        align-items: center;
        column-gap: 12px;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 12px;

        font-size: 14px;
        line-height: 14px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--lineColor);
        }

        & > a {
          color: var(--titleColor);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          text-decoration: none;
        }
      }
    }
  }

  .c__app_header_steps_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: auto;

    gap: 12px;

    &_break {
      background-color: var(--subTitleColor);
      height: 2px;
      width: 49px;
    }

    .c__app_header_step {
      display: flex;
      justify-content: center;
      align-items: center;

      gap: 16px;

      .icon_active {
        border: 1px solid transparent;
        background: var(--primaryColor);
      }

      .icon_inactive {
        border: 1px solid transparent;
        background: var(--primaryColor);
      }

      .icon_default {
        border: 1px solid var(--infoColor);
        background: transparent;
      }

      .inner_icon_inactive {
        background: var(--bgPrimary);

        border-radius: 100%;

        width: 15px;
        height: 15px;

        min-width: 15px;
        min-height: 15px;
      }

      &_icon {
        color: var(--titleColor);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        width: 28px;
        height: 28px;

        min-width: 28px;
        min-height: 28px;
      }

      &_text {
        min-width: max-content;
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-transform: uppercase;

        padding-top: 4px;
      }
    }

    .allow_step_back {
      cursor: pointer;
    }
  }

  .btn_start {
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;

    opacity: 0;

    .c__app_header_step {
      display: none;
    }
  }

  .btn_end {
    -webkit-transition: opacity 0.5s ease-in;
    -moz-transition: opacity 0.5s ease-in;
    -ms-transition: opacity 0.5s ease-in;
    -o-transition: opacity 0.5s ease-in;
    transition: opacity 0.5s ease-in;

    opacity: 1;
  }
}
