.student_route {
  .health-questionnaire {
    &-table {
      margin-top: 24px;

      & > div > .app-table .content-table .table-content-item {
        margin: 12px !important;
      }

      &_quest {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-transform: uppercase;
      }
    }
  }
}
