.app_header__wrapper {
  width: 100%;
  height: 84px;
  padding: 25px 30px 25px 30px;

  border-bottom: 1px solid var(--lineColor);
  background: var(--bgPrimary);

  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;

  position: fixed;
  top: 0;
  z-index: 9;
  right: 0;
  width: calc(100% - 280px);

  &-collapsed {
    padding-left: 0px;
  }

  .app_header {
    display: flex;
    align-items: center;
    column-gap: 20px;

    &-title {
      color: var(--titleColor);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.5px;
      height: 34px;
      text-decoration: none;
    }

    &-items {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 12px;

      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--lineColor);
      }

      & > a {
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-decoration: none;
      }
    }
  }

  .app_header_steps_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 12px;

    &_break {
      background-color: var(--subTitleColor);
      height: 2px;
      width: 49px;
      min-width: 49px;
    }

    .app_header_step {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      svg {
        path {
          color: var(--primaryColor);
        }
      }

      &_text {
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        text-transform: uppercase;

        padding-top: 4px;
      }
    }
  }
}
