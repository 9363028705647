.c__upload {
  // --- START --- UPLOAD ---
  .c__file-upload {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 190px;
    text-align: center;

    &-drag-element {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    &-input {
      display: none;
    }

    &-label {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      border-radius: 8px;
      border: 1px dashed var(--lineColor);
      transition: all 0.3s;

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 4px;

        &-image {
          width: 32px;
          height: 32px;
          & > svg {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }

        & > p {
          color: var(--titleColor);
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        & > span {
          color: var(--subTitleColor);
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        & .upload-button {
          cursor: pointer;
          color: var(--primaryColor);
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
        }
      }

      &.drag-active {
        opacity: 0.6;
        border-color: var(--titleColor);
      }
    }
  }

  .c__upload-documents {
    margin-top: 12px;
    &-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 12px;
    }
    &-item {
      position: relative;
      height: 115px;
      width: 20%;
      border-radius: 8px;
      border: 1px solid var(--lineColor);
      cursor: pointer;
      overflow: hidden;

      &-image {
        width: 100%;
        height: 100%;
        & > img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &:hover .c__upload-documents-item-overlay {
        display: flex;
      }

      &-overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);

        align-items: center;
        justify-content: center;
        column-gap: 12px;
        &-icon {
          border-radius: 8px;
          background: var(--bgPrimary);
          cursor: pointer;
          width: 30px;
          height: 30px;
          padding: 4px;
          & > svg {
            width: 22px;
            height: 22px;
            display: block;
            width: 100%;
            object-fit: contain;
          }

          &:first-child:hover {
            background-color: var(--primaryColor);
          }
          &:last-child:hover {
            background-color: var(--errorColor);
          }
        }
      }
    }
  }

  .c__upload-files {
    margin-top: 24px;

    &-container {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 12px 12px 24px;
      border-radius: 8px;
      border: 1px solid var(--lineColor);

      &-content {
        display: flex;
        align-items: center;
        column-gap: 12px;
        &-image {
          width: 24px;
          height: 24px;
          & > img {
            display: block;
            width: 100%;
            height: 100%;
          }
          & > embed {
            width: 100%;
            height: 100%;
            max-height: 100%;
            overflow-y: hidden !important;
          }
        }
        &-name {
          overflow: hidden;
          color: var(--titleColor);
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
      }

      &-action {
        display: flex;
        align-items: center;
        column-gap: 24px;
        &-progress {
          height: 5px;
          width: 190px;
          &[value]::-webkit-progress-bar {
            border-radius: 6px;
            background-color: var(--bgTertiary);
          }
          &[value]::-webkit-progress-value {
            border-radius: 6px;
            background-color: var(--primaryColor);
          }
        }
        &-delete {
          cursor: pointer;
          border-radius: 8px;
          border: 1px solid var(--lineColor);
          background: transparent;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          & > div {
            width: 24px;
            height: 24px;
            & > svg {
              display: block;
              width: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  // --- END --- UPLOAD ---

  // --- START --- SLIDER ---
  .c__documents-slider {
    z-index: 60;
    // overflow: visible;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 62.5vw;
    height: 60vh;

    max-width: 900px;
    max-height: 600px;

    background-color: var(--bgPrimary);
    border-radius: 8px;
    border: 1px solid var(--lineColor);

    &-header {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 72px;
      background: linear-gradient(
        180deg,
        var(--bgPrimary) 0%,
        rgba(28, 28, 36, 0) 100%
      );
      padding: 24px;
      & > p {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
      & > div > svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    // override swiper
    .swiper {
      overflow: visible;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 78%;
      height: 100%;
      &::before {
        display: block;
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        width: 15%;
        height: 100%;
        background-color: var(--bgPrimary);
        z-index: 5;
      }
      &::after {
        display: block;
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        width: 15%;
        height: 100%;
        background-color: var(--bgPrimary);
        z-index: 5;
      }
    }
    .swiper-wrapper {
    }
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      left: -52px;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      color: white;
      &::after {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .swiper-button-next {
      width: 40px;
      height: 40px;
      right: -52px;
      transform: translateX(50%);
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      color: white;
      &::after {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .swiper-slide {
      background-color: var(--bgPrimary);
      & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  // --- END --- SLIDER ---
}
