
.class-filter-component {
    position: fixed;
    background-color: var(--bgSecondary);
    right: 0;
    top: 0;
    border-left: 1px solid #40404a;
    border-bottom: 1px solid #40404a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh);
    z-index: 13;
    max-width: 420px;
    width: 0;
    transition: all 0.3s ease-out;
    &.open {
      width: 380px;
      transition: all 0.3s ease-in;
    }
    .footer-filter {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      border-top: 1px solid #40404a;
    }
    .filter-title {
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 8px;
    }
    .filter-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--lineColor);
      &-text {
        padding: 0;
        border-bottom: none;
        svg {
          cursor: pointer;
        }
      }
    }
    .reset-label {
      color: var(--primaryColor);
      font-size: 16px;
      cursor: default;
    }
    .filter-body {
      padding: 24px;
    }
    .apply-btn {
      font-size: 16px;
      text-transform: uppercase;
      width: auto;
      white-space: nowrap;
    }
  }