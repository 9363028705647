.classDetail__ellipsisVertical {
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;

  position: relative;
  padding: 0;
  border: none;

  & svg {
    width: 24px;
    height: 24px;
    padding: 3px;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: var(--primaryColor);
    }
  }

  .viewDetailPopup {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 12;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

    padding: 8px 0px;

    &-item {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      white-space: nowrap;
      padding: 10px 24px;
      cursor: pointer;
      text-transform: none;
    }

    &-horizontal {
      height: 1px;
      width: 100%;
      background-color: var(--lineColor);
      margin: 8px 0px;
    }
  }
}
