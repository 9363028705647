#assessmentListPage {
  $activeStar: #fecb18;

  .assessments {
    display: flex;
    align-items: flex-start;

    column-gap: 24px;
    width: 100%;
    min-height: 100%;

    transition: all 0.5s ease-in-out;

    overflow-x: hidden;

    position: relative;

    &__filter {
      position: fixed;
      z-index: 30;
      top: 84px;
      right: -380px;
      height: calc(100% - 84px);
      width: 380px;
      display: flex;
      flex-direction: column;
      border-left: 1px solid var(--lineColor);
      background: var(--bgPrimary);
      transition: all 0.3s;

      &.active {
        right: 0;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
        border-bottom: 1px solid var(--lineColor);
        padding: 16px 24px;
        & > svg {
          cursor: pointer;
        }
        &--title {
          flex: 1;
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          text-transform: uppercase;
        }
        &--reset {
          color: var(--primaryColor);
          text-align: right;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          cursor: pointer;
        }
      }
      &__content {
        flex: 1;
        height: 100%;
        padding: 24px;
      }
      &__actions {
        border-top: 1px solid var(--lineColor);
        padding: 20px 24px 24px 24px;
      }
    }

    &__detail.showAssessmentDetails {
      width: 100%;
      transition: transform 0.35s;
      transition-timing-function: ease-in;
      transform: translateY(0px);
    }

    &__detail.hideAssessmentDetails {
      width: 0px;
      transition: transform 0.15s;
      transition-timing-function: ease-in;
      transform: translateX(280px);
    }

    &__detail {
      min-width: 350px;
      max-width: 350px;
      row-gap: 0;

      right: 30px;
      width: 0px;

      &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 10px;
        &--title {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 28px;
          color: var(--titleColor);
        }

        width: 100%;
        padding-bottom: 24px;

        & svg {
          cursor: pointer;
        }
      }

      &__content {
        padding-top: 12px;
        padding-bottom: 12px;

        .skills {
          display: flex;
          flex-direction: column;
          row-gap: 24px;

          .skill {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            padding: 20px;
            border-radius: 8px;
            border: 1px solid var(--lineColor);

            &__title {
              color: var(--titleColor);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 22px;
              text-transform: uppercase;
            }

            &__milestones {
              display: flex;
              flex-direction: column;
            }

            &__milestone {
              display: flex;
              flex-direction: column;
              row-gap: 12px;
              padding-top: 12px;
              padding-bottom: 12px;

              &__title {
                color: var(--titleColor);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-transform: uppercase;
              }
              &__stars {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: flex-end;
                column-gap: 8px;
              }

              &__star {
                width: 24px;
                height: 24px;
                &.active {
                  fill: $activeStar;
                }
              }
            }
          }
        }
      }

      &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 24px;

        border-top: 1px solid var(--lineColor);
        width: 100%;
        padding-top: 22px;

        & .btn {
          white-space: nowrap;

          &-secondary {
            background-color: var(--errorColor);
            border-color: var(--errorColor);
          }
        }
      }
    }

    &__main {
      min-width: 100%;

      transition: min-width 0.3s;
      transition-timing-function: ease-in-out;

      &.active {
        min-width: calc(100% - 374px);
      }

      & .assessments__tab {
        position: relative;
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.3px;
        text-transform: uppercase;

        cursor: pointer;
        padding: 12px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 4px;
          width: 0%;
          background-color: var(--primaryColor);
          transition: all 0.3s;
        }

        &:hover,
        &.active {
          &::after {
            width: 100%;
          }
        }
      }

      & .assessments__table {
        row-gap: 0px;
        .table-content-item {
          cursor: pointer;
        }

        &-header {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          &-title {
            color: var(--titleColor);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            text-transform: uppercase;
          }

          &-search {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            .c__input-search {
              min-width: 392px;
            }

            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 10px;
            }

            .c__select {
              padding-top: 0px;
              padding-bottom: 0px;
              width: 200px;
              & .c__select-field {
                height: 44px;
                min-height: 44px;
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }
          }
        }

        &__levels {
          .swiper-wrapper {
            align-items: flex-end;
          }
          width: 100%;
          margin-top: 12px;

          &::-webkit-scrollbar {
            display: none;
          }

          &-item {
            color: var(--titleColor);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;

            padding: 8px 6px;
            cursor: pointer;
            width: fit-content !important;
            white-space: nowrap;

            border-radius: 8px;
            border: 1px solid var(--lineColor);
            border-top: 3px solid var(--primaryColor);

            &.active {
              margin-bottom: 4px;
            }
          }
        }

        &-content {
          margin-top: 24px;

          &.multiple-mode {
            & .table-header-item:first-child {
              width: 1% !important;
            }
          }

          .app-table {
            overflow-x: auto;

            .buttonGroups {
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 8px;

              .assessmentInStudentDetail__ellipsisVertical {
                display: flex;
                justify-content: center;
                align-items: center;

                transition: all 0.3s;

                position: relative;
                padding: 0;
                border: none;

                & svg {
                  width: 24px;
                  height: 24px;
                  padding: 3px;
                  border-radius: 4px;
                  background-color: transparent;
                  cursor: pointer;

                  &:hover {
                    background-color: var(--primaryColor);
                  }
                }

                .viewDetailPopup {
                  position: absolute;
                  top: 100%;
                  right: 0;
                  z-index: 10;

                  border-radius: 8px;
                  border: 1px solid var(--lineColor);
                  background: var(--bgPrimary);
                  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

                  padding: 8px 0px;

                  &-item {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    white-space: nowrap;
                    padding: 10px 24px;
                    cursor: pointer;
                    text-transform: none;
                  }

                  &-horizontal {
                    height: 1px;
                    width: 100%;
                    background-color: var(--lineColor);
                    margin: 8px 0px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 12px;
      background: transparent;

      position: fixed;
      bottom: 16px;
      right: 24px;
      width: 100%;
      z-index: 12;
    }
  }
}
