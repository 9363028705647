.c_footer-app-enrollment {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 51px;
  background: var(--bgPrimary);
  z-index: 12;
  border-top: 0.5px solid var(--lineColor);
  padding: 15px 33px 15px 30px;
  font-size: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &_content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_btn {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
