.booking-classes {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .student-name {
    height: 44px;
    display: flex;
    font-weight: 500;
    span {
      border-bottom: 4px solid var(--primaryColor);
      padding: 12px;
    }
  }
  &-item {
    border: 1px solid var(--lineColor);
    border-radius: 8px;
    background-color: var(--bgPrimary);
    .class-name {
      display: flex;
      padding: 20px 24px;
      border-bottom: 1px solid var(--lineColor);
      justify-content: flex-start;
      align-items: center;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 700;
      svg {
        path {
          color: var(--primaryColor);
        }
      }
    }
    .class-date {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 500;
    }
    .select-class-session {
      padding: 24px;
      .table-header-item:first-child {
        width: 1% !important;
      }
      .schedules {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
    .no-slot {
      color: var(--warningColor);
      padding: 24px;
    }
  }
}
