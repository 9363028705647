.header-calendar,
.content-wrap {
  display: flex;
  z-index: 0;
  background: var(--bgPrimary);
}
.header-calendar-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
  width: 100%;
}
.header-item,
.content-item {
  flex: 1;
  text-align: center;
  border-left: none;
}

.content-item-wrap {
  width: calc(100% - 10px);
  height: 100%;
  position: relative;
}

.header-item {
  border: 1px solid var(--lineColor);
  border-left: none;
  padding: 15px;
  cursor: pointer;
  min-width: 75px;
}

.header-time {
  min-width: 35px;
  border: 1px solid var(--lineColor);
  border-bottom: 1px solid var(--lineColor);
}

.content-calendar {
  display: flex;
  border: 1px solid var(--lineColor);
  border-top: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.time-wrap {
  position: relative;
}

.content-wrap {
  width: 100%;
  position: relative;
}

.time-item {
  width: 35px;
  height: 150px;
  border-right: 1px solid var(--lineColor);
  position: relative;
}

.time {
  position: absolute;
  top: -5px;
  right: 2px;
  font-size: 10px;
}

.line-content {
  height: 150px;
  /* border: 1px solid var(--lineColor); */
  border-bottom: none;
}

.main-content {
  background-color: #123056;
  margin-top: 1px;
  position: absolute;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: left;
  overflow: hidden;
  cursor: pointer;
}

.main-content-wrap {
  padding: 10px;
  height: 100%;
}

.line-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.modeViewSelect.open {
  display: block;
}
.modeViewSelect.open-saved-filter {
  display: block;
  width: 150%;
  right: 0;
  border: none;
}
.modeViewSelect {
  position: absolute;
  margin-left: 24px;
  background-color: var(--bgSecondary);
  border: 1px solid var(--lineColor);
  border-radius: 4px;
  z-index: 100;
  width: 85%;
  margin-top: 1px;
  display: none;
}

.day-label {
  font-size: 12px;
  margin-bottom: 6px;
}
.date-label {
  font-size: 26px;
  font-family: Gotham;
  padding: 5px;
  font-weight: 700;
}
.date-label.today {
  background-color: #034ea2;
  border-radius: 99px;
  width: 36px;
  text-align: center;
  box-sizing: content-box;
}
.header-item {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  &.public-holiday-header {
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    background-color: rgba(3, 78, 162, 0.2);
  }
}
.public-holiday {
  border-radius: 99px;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  background-color: var(--primaryColor);
  padding: 2px 6px;
  text-align: center;
}

.time-class {
  font-size: 11px;
  margin-top: 30px;
}
.time-title {
  font-size: 14px;
  line-height: 19.6px;
}

.current-time {
  width: 100%;
  position: absolute;
  z-index: 0;
  border: 0.5px solid red;
  left: 0;
}

.current-time-text {
  color: red;
  position: absolute;
  font-size: 8px;
  text-align: center;
  z-index: 9;
}
.color-by-level-container {
  display: flex;
  gap: 8px;
  width: 100%;
  overflow-x: scroll;
  position: relative;
  align-items: flex-end;
  &::-webkit-scrollbar {
    display: none;
  }
  .color-by-level {
    display: flex;
    padding: 8px 6px;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    gap: 8px;
    font-size: 12px;
    align-items: center;
    height: 33px;
    cursor: pointer;
    border-top: 3px solid var(--primaryColor);
    &.active {
      background-color: var(--bgTertiary);
      margin-bottom: 4px;
      .color-box {
        border-color: var(--titleColor);
      }
    }
    .color-box {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid var(--lineColor);
      display: flex;
      align-items: center;
    }
    .level-name {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
