.levelBreakdownDetailPage {
  .levelBreakdownDetail {
    &__top {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: stretch;
      gap: 24px;
      margin-bottom: 24px;

      &-left {
        height: 100%;
      }
      &-right {
        height: 100%;
        min-width: 384px;
        .table-content-item td {
          padding: 14px;
        }
      }
    }

    .levelAssessmentScore {
      height: auto;
      .c__card {
        height: 100%;
      }
      & .c__card.edit {
        padding-bottom: 8px;
      }

      .cardContent {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 24px;
        row-gap: 20px;
        &-edit {
          row-gap: 8px;
        }

        & > div:last-child {
          grid-column: span 2 / span 2;
        }
      }
    }

    .levelInformation {
      height: 100%;

      &__description {
        grid-column: span 2 / span 2;

        & * {
          text-transform: initial;
        }
        & ol,
        ul {
          padding-left: 20px;
        }
      }

      .c__card {
        height: 100%;
      }

      .cardContent {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 24px;
        row-gap: 20px;
        &-edit {
          row-gap: 8px;
          align-items: flex-start;
          & .description {
            grid-column: span 2 / span 2;
            margin-bottom: 16px;
            &--label {
              color: var(--titleColor);
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
              text-transform: uppercase;
              margin-bottom: 16px;
            }
            &--error {
              margin-top: 4px;
              font-size: 12px;
              font-weight: 400;
              line-height: 140%;
              color: var(--errorColor);
            }
          }
        }

        .colorPickerWrapper {
          position: relative;
          margin-top: 20px;

          .colorPicker {
            height: 54px;
            border-radius: 8px;
            cursor: pointer;
          }

          & > .pick-color {
            position: absolute;
            left: 0 !important;
            top: calc(100% + 12px);
            & svg {
              cursor: pointer;
            }
          }
        }

        & .block-color {
          width: 55px !important;
          height: 22px !important;
          border-radius: 4px !important;
        }
        .imageContainer {
          display: flex;
          gap: 8px;
          .image {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            border: 1px solid var(--lineColor);
            border-radius: 8px;
            overflow: hidden;
          }
        }
        .imageWrapper {
          &-images {
            display: flex;
            gap: 24px;
            margin-top: 16px;
            &-title {
              color: var(--titleColor);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            }
            &-error {
              color: var(--errorColor);
              font-size: 12px;
              font-style: normal;
              line-height: 140%; /* 19.6px */
            }
          }
          &-edit {
            .c__file-uploadAvatar {
              border: 1px solid var(--lineColor);
              border-radius: 8px;
            }
          }
        }
      }
    }

    &__searchContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;
      margin-bottom: 24px;
      & svg {
        width: 22px;
        height: 22px;
      }
      .c__input-search {
        min-width: 478px;
      }
      .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        & p {
          color: var(--titleColor);
          font-size: 16px;
          padding-top: 2px;
          line-height: 16px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
        }
      }
    }
    &__nextLevel {
      margin-bottom: 24px;
    }

    .icon {
      width: 30px;
      height: 30px;
      padding: 3px;
      cursor: pointer;
      & svg {
        width: 24px;
        height: 24px;
        display: block;
        object-fit: contain;
      }
    }

    .levelSkill {
      margin-bottom: 24px;

      .c__card-content-item {
        row-gap: 6px;
        .c__card-content-item-sub {
          font-size: 14px;
          line-height: 20px;
        }
        .c__card-content-item-title {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .milestones {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        flex-wrap: wrap;
        align-items: stretch;
        gap: 12px;
        margin-top: 12px;
        &.edit {
          margin-top: 0px;
        }
      }
    }
  }

  .levelDetailSkillAdd {
    margin-bottom: 24px;
    &__form {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
      & > .c__input {
        grid-column: span 3 / span 3;
      }

      &-remove {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid var(--lineColor);
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          border-color: var(--errorColor);
          background-color: var(--errorColor);
        }

        & > svg,
        img {
          display: block;
          object-fit: contain;
          width: 24px;
          height: 24px;
        }
      }
    }
    &__milestones {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 12px;
    }
  }

  .levelAssessmentScoreEdit {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &-score {
      &-title {
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        margin-bottom: 4px;
      }

      &-form {
        border-radius: 8px;
        border: 1px solid var(--lineColor);
        height: 54px;

        display: flex;
        align-items: center;
        overflow: hidden;

        &-input {
          height: 100%;
          width: 100%;
          background-color: transparent;
          outline: none;
          padding: 16px 12px;
          border-right: 1px solid var(--lineColor);

          color: var(--titleColor);
          font-size: 16px;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 54px;
          height: 54px;
          padding: 16px;
          transition: all 0.3s;
          cursor: pointer;

          & > svg,
          img {
            display: block;
            object-fit: contain;
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    &-scoreDetails {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      &-form {
        border-radius: 8px;
        height: 54px;
        border: 1px solid var(--lineColor);
        display: flex;
        align-items: center;
        overflow: hidden;

        &-remove {
          flex: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 100%;
          padding: 16px;
          border-right: 1px solid var(--lineColor);
          transition: all 0.3s;
          cursor: pointer;

          &:hover {
            border-color: var(--errorColor);
            background-color: var(--errorColor);
          }

          & > svg,
          img {
            display: block;
            object-fit: contain;
            width: 22px;
            height: 22px;
            flex: none;
          }
        }

        &-input {
          height: 100%;
          width: 100%;
          background-color: transparent;
          outline: none;
          padding: 16px 12px;
          border-right: 1px solid var(--lineColor);

          color: var(--titleColor);
          font-size: 16px;
          font-weight: 400;
          line-height: 140%; /* 22.4px */

          &[type='text'] {
            flex: 1;
          }
          &[type='number'] {
            max-width: 54px;
            text-align: center;
          }
        }

        &-of {
          flex: none;
          text-align: center;
          width: 52px;
          height: 100%;
          padding: 16px;
          border-right: 1px solid var(--lineColor);
          background: var(--lineColor);
        }
        &-maxScore {
          width: 54px;
          height: 54px;
          background-color: transparent;
          outline: none;
          padding: 16px;
          color: var(--titleColor);
          font-size: 16px;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          text-align: center;
        }
      }
    }
  }
}
