.userGroupDetailPage {
  .icon {
    width: 30px;
    height: 30px;
    padding: 3px;
    cursor: pointer;
    & svg {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: contain;
    }
  }
  .userGroupDetail {
    &__card {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    &__content {
      &-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 24px;
      }

      &-permissions {
        margin-top: 24px;
        row-gap: 12px;

        &-edit {
          margin-top: 0px;
        }

        .c__select {
          width: calc(100% / 3);
          margin-bottom: 16px;
        }
        &-all {
          margin-top: -12px;
        }
        &-list {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: flex-start;
          column-gap: 24px;
          row-gap: 12px;
        }
        &-item {
          text-transform: capitalize;
        }
        &-noItem {
          color: var(--warningColor);
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          display: flex;
          align-items: center;
          column-gap: 4px;
          & strong {
            color: var(--warningColor);
            font-weight: 700;
          }
          & svg {
            width: 18px;
            height: 18px;
            stroke: var(--warningColor);
          }
        }
      }
    }

    .tabContainer {
      .tabs {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        .tab {
          color: var(--labelColor);
          font-size: 16px;
          font-weight: 400;
          line-height: 140%;
          text-transform: uppercase;
          padding: 15px 20px;
          cursor: pointer;
          border-bottom: 4px solid transparent;

          &-active {
            border-bottom: 4px solid var(--primaryColor);
            color: var(--titleColor);
          }
        }
      }

      .tabPanel {
        .staffs__table {
          &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;

            &-search {
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 8px;

              & svg {
                width: 22px;
                height: 22px;
              }

              .c__input-search {
                min-width: 478px;
              }

              .btn {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 8px;
                & .plusIcon {
                  width: 22px;
                  height: 22px;
                  & > img {
                    display: block;
                    width: 100%;
                    object-fit: contain;
                  }
                }

                & p {
                  color: var(--titleColor);
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 140%; /* 22.4px */
                  letter-spacing: 0.3px;
                  text-transform: uppercase;
                }
              }
            }
          }
          &-content {
            margin-top: 24px;
            .app-table {
              & .table-header-item:first-child {
                width: 80px !important;
              }
              & .table-header-item:nth-child(2) {
                min-width: 250px;
              }

              .buttonGroups {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 12px;
                & div {
                  padding: 3px;
                  width: 24px;
                  height: 24px;
                  cursor: pointer;
                  & svg {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
        .permissionBlock {
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          & input[type='checkbox']:checked ~ .permissionBlock-items {
            display: flex;
          }

          & input[type='checkbox']:checked + .permissionBlock-name {
            & > label {
              transform: rotate(90deg);
            }
          }

          &-name {
            display: flex;
            align-items: center;
            column-gap: 8px;

            & svg {
              cursor: pointer;
            }
          }
          &-items {
            margin-left: 32px;

            display: none;
            flex-direction: column;
            row-gap: 8px;
          }
          &-item {
            font-size: 12px;
            text-transform: capitalize;
            &.active {
              color: var(--titleColor);
            }
            &.inactive {
              color: var(--labelColor);
            }
          }
        }
      }
    }
  }
}
