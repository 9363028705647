.responsible_person_route {
  .personal-information {
    .card-content {
      display: grid;
      gap: 24px;

      .personal-information-main-content {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .content {
          .responsible_person-personal_information_wrapper {
            display: flex;
            gap: 24px;

            .avatar__container {
              position: relative;
              width: max-content;

              .remove__avatar {
                cursor: pointer;
                display: flex;
                position: absolute;
                border-radius: 100%;
                background: var(--lineColor);

                top: -10px;
                right: -10px;
              }
            }

            .responsible_person-personal_information_cover {
              display: grid;
              grid-template-columns: repeat(6, minmax(0, 1fr));
              column-gap: 24px;
              row-gap: 20px;
              height: fit-content;

              width: 100%;

              &-isEdit {
                column-gap: 24px;
                row-gap: 36px;
              }

              .item_tittle {
                grid-column: span 6;

                margin-bottom: 8px;

                .icon {
                  width: 30px;
                  height: 30px;
                  padding: 3px;
                  cursor: pointer;
                  & svg {
                    width: 24px;
                    height: 24px;
                    display: block;
                    object-fit: contain;
                  }
                }
              }

              .item {
                grid-column: span 2;

                // fix date picker not overflow
                .MuiFormControl-root {
                  position: static;
                }

                .c__input,
                .c__select,
                .c__datePicker_wrapper,
                .c__phoneNumberInput-wrapper {
                  padding-bottom: 0px;
                }

                .c__select,
                .MuiStack-root,
                .c__phoneNumberInput-wrapper {
                  padding-top: 0px;
                }

                .c__input:first-child {
                  padding-top: 0px;
                }
              }

              .item_custom {
                grid-column: span 3;
              }

              .padY {
                padding-bottom: 24px;
              }
            }
          }
        }
      }
    }
  }
}
