.responsiblePersonAddContainer {
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    z-index: 11;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: all 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .responsiblePersonAddForm {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 12;

    display: flex;
    flex-direction: column;

    min-width: calc(100vw - 280px);
    height: 100vh;
    background-color: var(--bgPrimary);
    border: 1px solid var(--lineColor);
    transition: all 0.3s;
    overflow: hidden;

    &.active {
      left: 280px;
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 24px;
      border-bottom: 1px solid var(--lineColor);
      & > div {
        width: 24px;
        height: 24px;
        cursor: pointer;
        & > img {
          display: block;
          width: 100%;
          object-fit: contain;
        }
      }
      & > p {
        color: var(--titleColor);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 28px */
        text-transform: uppercase;
      }
    }

    &__content {
      overflow-y: auto;
      flex: 1;
      padding: 24px 24px 24px 24px;

      .responsiblePersonAddForm__content-responsible_person_add_information_wrapper {
        display: flex;
        gap: 24px;

        .avatar__container {
          position: relative;
          width: max-content;

          .remove__avatar {
            cursor: pointer;
            display: flex;
            position: absolute;
            border-radius: 100%;
            background: var(--lineColor);

            top: -10px;
            right: -10px;
          }
        }

        .responsiblePersonAddForm__content-responsible_person_add {
          display: grid;
          grid-template-columns: repeat(6, minmax(0, 1fr));
          column-gap: 24px;
          row-gap: 36px;

          height: fit-content;

          .item_tittle {
            grid-column: span 6;

            margin-bottom: 8px;
          }

          .item {
            grid-column: span 2;

            // fix date picker not overflow
            .MuiFormControl-root {
              position: static;
            }

            .c__input,
            .c__select,
            .c__datePicker_wrapper,
            .c__phoneNumberInput-wrapper {
              padding-bottom: 0px;
            }

            .c__select,
            .MuiStack-root,
            .c__phoneNumberInput-wrapper {
              padding-top: 0px;
            }

            .c__input:first-child {
              padding-top: 0px;
            }
          }

          .item_custom {
            grid-column: span 3;
          }

          .responsiblePersonAddForm__content-responsible_person_add_field_err {
            color: var(--errorColor);
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;
          }
        }
      }

      .responsiblePersonAddForm__content-responsible_person_add_contact_wrapper {
        margin-top: 48px;

        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        column-gap: 24px;
        row-gap: 36px;

        .item_tittle {
          grid-column: span 6;

          margin-bottom: 8px;
        }

        .item {
          grid-column: span 2;

          // fix date picker not overflow
          .MuiFormControl-root {
            position: static;
          }

          .c__input,
          .c__select,
          .c__datePicker_wrapper,
          .c__phoneNumberInput-wrapper {
            padding-bottom: 0px;
          }

          .c__select,
          .MuiStack-root,
          .c__phoneNumberInput-wrapper {
            padding-top: 0px;
          }

          .c__input:first-child {
            padding-top: 0px;
          }
        }

        .item_custom {
          grid-column: span 3;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      column-gap: 24px;
      padding: 24px;
      border-top: 1px solid var(--lineColor);
      & > button {
        cursor: pointer;
      }
    }
  }
}
