.promoCode--wrapper {
  display: flex;
  padding: 8px 12px;
  background-color: var(--bgTertiary);
  gap: 12px;
  align-items: center;
  width: 100%;
  min-height: 41px;
  text-transform: initial;
  span {
    color: var(--titleColor);
    font-size: 14px;
  }
  .c__input {
    padding: 0;
  }
  .btn-secondary {
    background-color: var(--bgTertiary);
  }
  .promoCode--valid {
    &-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      .text {
        color: var(--successColor);
        font-size: 12px;
        font-weight: 500;
      }
    }
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: var(--bgPrimary);
    cursor: pointer;

    .text {
      color: var(--successColor);
      font-size: 12px;
      font-weight: 500;
    }
  }
  .promoCode--input-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .automaticDiscount {
    &--wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      background-color: var(--bgSecondary);
      border-radius: 8px;
      padding: 4px 8px;
      .text {
        color: var(--successColor);
        font-size: 12px;
        font-weight: 500;
      }
    }
    &--text {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
      color: var(--successColor);
    }
    &--type {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
      color: var(--subTitleColor);
    }
  }
}
