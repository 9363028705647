// Default theme | DARK
$theme-map-dark: (
  // Text color
  --titleColor: #ffffff,
  --subTitleColor: #85858f,
  --labelColor: #63636e,
  --placeholderColor: #8e8e96,
  --lineColor: #40404a,

  // Background color
  --bgPrimary: #1c1c24,
  --bgSecondary: #13131a,
  --bgTertiary: #292933,
  --bgClass: #fd7c04,

  // State color
  --primaryColor: #034ea2,
  --errorColor: #eb5757,
  --successColor: #0bb783,
  --warningColor: #f4b740,
  --infoColor: #919199,

  --lightBlueColor: #1b84ff,
  --lightBlue10Color: #e8f3ff,

  --lightGreenColor: #0ead64,
  --lightPink: #f1416c
);

// Override the default theme | LIGHT
$theme-map-light: (
  // Text color
  --titleColor: #ffffff,
  --subTitleColor: #85858f,
  --labelColor: #63636e,
  --placeholderColor: #8e8e96,
  --lineColor: #40404a,

  // Background color
  --bgPrimary: #1c1c24,
  --bgSecondary: #13131a,
  --bgTertiary: #292933,
  --bgClass: #fd7c04,

  // State color
  --primaryColor: #034ea2,
  --errorColor: #eb5757,
  --successColor: #0bb783,
  --warningColor: #f4b740,
  --infoColor: #919199,

  --lightBlueColor: #1b84ff,
  --lightBlue10Color: #e8f3ff
);
