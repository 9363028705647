// ADD FORM
.userGroupAddContainer {
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    z-index: 11;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: all 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .userGroupAddForm {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 12;

    display: flex;
    flex-direction: column;

    min-width: calc(100vw - 280px);
    height: 100vh;
    background-color: var(--bgPrimary);
    border: 1px solid var(--lineColor);
    transition: all 0.3s;
    overflow: hidden;

    &.active {
      left: 280px;
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 24px;
      border-bottom: 1px solid var(--lineColor);
      & > div {
        width: 24px;
        height: 24px;
        cursor: pointer;
        & > svg {
          width: 24px;
          height: 24px;
        }
      }
      & > p {
        color: var(--titleColor);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 28px */
        text-transform: uppercase;
      }
    }
    &__content {
      overflow-y: auto;
      flex: 1;
      padding: 24px 24px 12px 24px;

      &-wrapper {
        display: flex;
        flex-direction: column;
      }

      &-fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 24px;
        margin-top: 16px;
      }
      &-permissions {
        margin-top: 24px;
        row-gap: 12px;
        .c__select {
          width: calc(100% / 3);
          margin-bottom: 16px;
        }
        &-all {
          margin-top: -12px;
        }
        &-list {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: flex-start;
          column-gap: 24px;
          row-gap: 12px;
        }
        &-item {
          text-transform: capitalize;
        }
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      column-gap: 24px;
      padding: 24px;
      border-top: 1px solid var(--lineColor);
      & > button {
        width: 120px;
        cursor: pointer;
      }
    }
  }
}
