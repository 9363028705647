.changeAreaModal,
.changeInstructorModal,
.changeClassDetailModal {
  .c__modal-main {
    width: 600px;
    .divider {
      border: 1px solid var(--lineColor);
      margin: 12px 0 24px 0;
      height: 1px;
    }
  }
  .c__modal-content * {
    text-transform: initial;
    text-align: initial;
  }
  p.description {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
  }
}
.successfullyModal {
  .c__modal-main {
    width: 500px;
    .c__modal-title {
      font-weight: 700;
    }
    img {
      display: flex;
      margin: 24px auto 0;
    }
  }
}
