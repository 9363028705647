.staffDetailPage {
  .icon {
    width: 30px;
    height: 30px;
    padding: 3px;
    cursor: pointer;
    & svg {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: contain;
    }
  }

  .personalInformation {
    margin-bottom: 24px;

    &-content {
      display: flex;
      column-gap: 24px;

      &-left {
      }
      &-right {
        width: 100%;
      }
    }

    .c__card-header {
      margin-bottom: 20px;
    }

    .cardContent {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      &-edit {
        row-gap: 0px;
      }

      & .customToggle {
        display: flex;
        align-items: center;
        column-gap: 12px;
        grid-column: span 3 / span 3;

        &-edit {
          margin-bottom: 18px;
        }

        & > p {
          color: var(--titleColor);
          font-size: 16px;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
      }
    }
  }
  .contactDetails {
    margin-bottom: 24px;

    .cardContent {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 24px;
      &-edit {
        row-gap: 0px;
      }
      & > div:nth-child(-n + 5) {
        grid-column: span 6 / span 6;
      }
      & > div:nth-child(n + 6) {
        grid-column: span 3 / span 3;
      }

      .custom_show_phone {
        display: flex;
        justify-content: space-between;
        align-items: center;

        word-break: normal;

        gap: 12px;

        & > button {
          aspect-ratio: 1;
          padding: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          border: none;

          margin-right: auto;

          & > svg {
            min-width: 20px;
            min-height: 20px;
          }
        }
      }
    }
  }
  .staffAccess {
    margin-bottom: 24px;
    .cardContent {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      &-edit {
        row-gap: 0px;
      }
    }
  }

  .editStaff__endDateWrapper {
    position: relative;
    & > .tooltip-trigger {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      & > .tooltip {
        left: unset;
        right: 0;
        width: auto;
      }
    }
  }
}
