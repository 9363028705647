.instructorsPage {
  .instructors {
    .instructors__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 18px;

          & svg {
            width: 22px;
            height: 22px;
          }

          .c__input-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;

            & p {
              color: var(--titleColor);
              font-size: 16px;
              padding-top: 2px;
              line-height: 16px;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }
      &-content {
        margin-top: 24px;
        .app-table {
          .buttonGroups {
            display: flex;
            align-items: center;
            column-gap: 12px;
            & div {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  td {
    .documentGroup {
      display: flex;
      column-gap: 8px;
    }
    .c__upload-files-item-content-image {
      img {
        width: 52px;
        height: 52px;
        border-radius: 8px;
      }
    }
  }
  .instructorsAddContainer {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 11;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transform: all 0.3s;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    .instructorsAddForm {
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 12;

      display: flex;
      flex-direction: column;

      min-width: calc(100vw - 280px);
      height: 100vh;
      background-color: var(--bgPrimary);
      border: 1px solid var(--lineColor);
      transition: all 0.3s;
      overflow: hidden;
      &__credential {
        * {
          text-transform: none;
          text-align: left;
        }
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-transform: none;
        width: 800px;
      }

      &.active {
        left: 280px;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 24px;
        border-bottom: 1px solid var(--lineColor);
        & > div {
          width: 24px;
          height: 24px;
          cursor: pointer;
          & > img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }
        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }
      }
      &__content {
        overflow-y: auto;
        flex: 1;
        padding: 24px 24px 12px 24px;

        &-wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 48px;
        }
        &-detail-wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }

        &-personal-information {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 24px;
        }
        &-contact-details {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 24px;
        }
        &-credentials {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 24px;
        border-top: 1px solid var(--lineColor);
        & > button {
          cursor: pointer;
        }
      }
    }
  }
}
