.c__avatar {
  overflow: hidden;
  border-radius: 50%;
  flex: none;
  background-color: var(--subTitleColor);

  display: flex;
  align-items: center;
  justify-content: center;

  &-size-small {
  }

  &-size-medium {
    width: 44px;
    height: 44px;
  }

  &-size-large {
  }

  &-name {
    color: var(--titleColor);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
