.table {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  .row {
    width: 100%;
    border-bottom: 1px solid var(--lineColor);
    .td:hover,
    .tr:hover {
      background-color: var(--bgTertiary);
    }
    table {
      tr {
        th {
          text-align: left;
          font-size: 14px;
          border: none;
        }
        td {
          padding: 8px 10px;
          font-size: 12px;
        }
      }
    }
  }

  .div {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--bgSecondary);
    border-radius: 4px;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1px;
  }
}

.head-instance {
  background-color: var(--bgSecondary);
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
}

.tr {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.td {
  align-items: baseline;
  align-self: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 8px 10px;
  position: relative;
  .text-wrapper-2 {
    font-size: 14px;
  }
}

.element {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
  .text-wrapper-2 {
    font-size: 12px;
  }
}

.div-wrapper-today {
  align-items: center;
  background-color: var(--primaryColor);
  border-radius: 99px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 40px;
}
.div-wrapper {
  align-items: center;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 36px;
}

.text-wrapper {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: default;
}

.element-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rectangle-2 {
  background-color: var(--primaryColor);
  border-radius: 10px;
  height: 10px;
  position: relative;
  width: 10px;
  margin-right: 10px;
}

.text-wrapper-3 {
  font-family: Gotham;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: default;
}

.text-wrapper-4 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.9;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
