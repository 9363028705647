.reportGraduatedAndNotMoved {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__content {
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    & .c__loading__container {
      position: absolute;
      top: 18%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      & > .c__input-search {
        width: 286px;
      }
    }

    &__table {
      margin-top: 24px;
      overflow-x: auto !important;

      &::-webkit-scrollbar {
        height: 5px !important;
      }
    }
  }
}
