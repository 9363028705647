.pick-color {
  .frame {
    background-color: var(--bgPrimary);
    border: 1px solid;
    border-color: var(--lineColor);
    overflow: hidden;
    position: relative;
    width: 280px;
    height: calc(100vh - 155px);
    z-index: 1;

    .div {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }

    .text-wrapper {
      color: var(--titleColor);
      font-family: Gotham;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      position: relative;
      white-space: nowrap;
      width: fit-content;
    }

    .div-2 {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .div-3 {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      position: relative;
      width: 260px;
    }

    .img {
      height: 243px;
      left: 0;
      top: 2px;
      width: 264px;
    }

    .hue-select-cursor {
      height: 9px;
      left: 256px;
      // position: absolute;
      top: 0;
      width: 9px;
    }

    .select-cursor {
      height: 9px;
    }

    .value-input-controls {
      align-items: flex-end;
      background-color: var(--bgPrimary);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: var(--lineColor);
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      margin: 0 10px;
    }

    .hex-input {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      gap: 20px;
      margin-bottom: 10px;
      width: 100%;
    }

    .text-wrapper-2 {
      color: var(--titleColor);
      font-family: Gotham;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
    }

    .div-4 {
      align-items: center;
      display: flex;
      flex: 1;
      flex-grow: 1;
      gap: 5px;
      position: relative;
    }
    .div-5 {
      border-radius: 4px;
      height: 30px;
      position: relative;
      width: 30px;
    }
  }

  .frame .element-wrapper {
    align-items: center;
    border: 1px solid;
    border-color: (var(--lineColor));
    border-radius: 4px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 8px 10px;
    position: relative;
    width: 148px;
  }

  .frame .element {
    color: transparent;
    font-family: Gothams;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame .span {
    color: #888888;
  }

  .frame .text-wrapper-3 {
    color: var(--titleColor);
    background-color: transparent;
    overflow: hidden;
    outline: none;
  }

  .frame .color {
    background-color: (var(--bgPrimary));
    padding: 10px;
    height: calc(100vh - 530px);
    overflow-y: scroll;
  }

  .frame .text-wrapper-4 {
    color: var(--titleColor);
    font-family: Gotham;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: 12px;
  }

  .frame .div-6 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    left: 0;
    // position: absolute;
    top: 29px;
  }

  .frame .color-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    position: relative;
  }

  .frame .red-box {
    background-color: #ef2525;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .text-wrapper-5 {
    color: var(--titleColor);
    font-family: Gotham;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.6px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame .orange-box {
    background-color: #f47c0d;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .yellow-box {
    background-color: #e9d415;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .green-box {
    background-color: #0eeb23;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .blue-box {
    background-color: #1b31fc;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .purple-box {
    background-color: #b60af3;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .pink-box {
    background-color: #fa086e;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .brown-box {
    background-color: #200e0e;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }

  .frame .gray-box {
    background-color: #3e4044;
    border-radius: 26px;
    height: 24px;
    position: relative;
    width: 24px;
  }
}
