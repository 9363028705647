.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--titleColor);
  transition: 0.4s;
  border-radius: 4px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: #1c1c24;
  transition: 0.4s;
  border-radius: 4px;
}

input:checked + .slider {
  background-color: #034ea2;
}

input:checked + .slider:before {
  transform: translateX(12px);
}
