.student_route {
  .emergency-contacts {
    .card-content {
      display: grid;
      gap: 24px;

      .emergency-contacts-main-content {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .icon {
          width: 30px;
          height: 30px;
          padding: 3px;
          cursor: pointer;
          & svg {
            width: 24px;
            height: 24px;
            display: block;
            object-fit: contain;
          }
        }

        .content {
          display: grid;
          grid-template-columns: repeat(13, minmax(0, 1fr));
          column-gap: 24px;

          &-btn_add_more {
            display: flex;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            border-radius: 8px;
            border: 1px solid var(--titleColor);

            width: max-content;
          }

          .item_single {
            grid-column: span 1;

            padding: 20px 0;

            min-width: min-content;

            & > button {
              aspect-ratio: 1;
              width: 54px;
              padding: 0;

              display: flex;
              justify-content: center;
              align-items: center;

              & > svg {
                min-width: 24px;
                min-height: 24px;
              }
            }
          }

          .item {
            grid-column: span 3;
          }

          .item_custom {
            grid-column: span 6;
          }

          .padY {
            padding-bottom: 24px;
          }

          .content_display {
            grid-column: span 13;

            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            column-gap: 24px;
            row-gap: 24px;

            .item_small {
              grid-column: span 2;
            }

            .item {
              grid-column: span 3;
            }

            .custom_show_phone {
              display: flex;
              justify-content: space-between;
              align-items: center;

              word-break: normal;

              gap: 12px;

              & > button {
                aspect-ratio: 1;
                padding: 0;

                display: flex;
                justify-content: center;
                align-items: center;

                border: none;

                margin-right: auto;

                & > svg {
                  min-width: 20px;
                  min-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
