.userGroupListPage {
  // MAIN || LIST
  .userGroupList {
    .userGroupList__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 8px;

          .c__input-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;

            & svg {
              width: 22px;
              height: 22px;
            }

            & p {
              color: var(--titleColor);
              font-size: 16px;
              padding-top: 2px;
              line-height: 16px;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }

      &-content {
        margin-top: 24px;
        .app-table {
          .table-content-item {
            & td:nth-child(2) {
              text-align: center;
              & .defined,
              .notDefined {
                justify-content: center;
              }
            }
          }
          .defined {
            display: flex;
            align-items: center;
            column-gap: 4px;
            color: var(--successColor);
            & svg {
              width: 18px;
              height: 18px;
              stroke: var(--successColor);
            }
          }
          .notDefined {
            display: flex;
            align-items: center;
            column-gap: 4px;
            color: var(--warningColor);
            & svg {
              width: 18px;
              height: 18px;
              stroke: var(--warningColor);
            }
          }
          .buttonGroups {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            & div {
              padding: 3px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              & svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
