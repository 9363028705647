.healthQuestionnaireDetailPage {
  .icon {
    width: 30px;
    height: 30px;
    padding: 3px;
    cursor: pointer;
    & svg {
      width: 24px;
      height: 24px;
      display: block;
      object-fit: contain;
    }
  }

  .healthQuestionnaireDetail {
    &__card {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .errText {
        color: var(--errorColor);
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;

        margin-top: 4px;
        margin-bottom: 4px;
      }

      &-edit {
        row-gap: 0px;
      }

      &-fields {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        column-gap: 24px;
        margin-top: 24px;
        & > div {
          grid-column: span 2 / span 2;
        }
        &-toggle {
          grid-column: span 1 / span 1 !important;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          &-item {
            display: flex;
            align-items: center;
            column-gap: 8px;
            & > p {
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 500;
              line-height: 140%;
              letter-spacing: 0.5px;
            }
          }
        }
      }
      &-fields-edit {
        & > div {
          grid-column: span 3 / span 3;
        }
      }
    }
  }
}
