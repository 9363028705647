$activeStar: #fecb18;

.c__classAssessmentModal {
  &-result-text {
    font-size: 16px;
    font-weight: 700;
    padding: 0 4px 10px 4px;
  }
  .c__modal-main {
    width: 60vw;

    .c__loading__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .c__modal-formTitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      & > p {
        color: var(--titleColor);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-transform: uppercase;
      }

      & > svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .c__modal-formContent {
      width: 100%;
      .select-all-wrapper {
        display: flex;
        align-items: center;
        margin: 12px 0;
      }
      .c__card {
        padding: 20px;
        row-gap: 10px;
        margin-bottom: 24px;

        .c__classAssessmentModal__skill-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
          min-width: 200px;
          text-transform: uppercase;
          &.warning {
            color: var(--warningColor) !important;
          }
          &-wrapper {
            min-width: 200px;
            display: flex;
            gap: 4px;
            align-items: flex-start;
            justify-content: center;
            .tooltip-trigger {
              height: 20px;
            }
            svg {
              fill: var(--warningColor);
            }
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }

        .c__card-header {
          &-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 22.4px;
          }
        }
        .c__card-content {
          .c__classAssessmentModal__skill {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 12px;
            padding: 12px 0px;
            border-bottom: 1px solid var(--lineColor);
            .c__textarea {
              padding: 0;
              min-width: 60%;
              .c__textarea-field {
                border: none;
                padding: 0;
                font-size: 14px;
              }
            }

            &:last-child {
              border-bottom-color: transparent;
            }

            &-name {
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              text-transform: uppercase;
            }
            &-scores {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
            }

            &-score {
              padding: 0px 4px;
              height: 24px;
              cursor: pointer;
              transition: all 0.3s;

              &:hover ~ svg,
              &:hover {
                fill: $activeStar;
              }

              &.active {
                fill: $activeStar;
              }

              &.disabled {
                pointer-events: none;
              }
            }
          }
        }
      }
    }

    .c__modal-formActions {
      & * {
        width: initial;
      }
    }
  }
}
