.loginPage {
  min-height: 100vh;
  background-color: var(--bgSecondary);

  .backButton {
    position: fixed;
    left: 32px;
    top: 36px;
    cursor: pointer;
  }

  .loginWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;

    .login {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--bgSecondary);
      max-width: 500px;
      width: 500px;
      margin-bottom: 20vh;

      .logo {
        width: 317px;
        height: auto;
        margin-bottom: 48px;
        img {
          display: block;
          width: 100%;
          object-fit: contain;
        }
      }

      .main {
        padding: 30px;
        background-color: var(--bgPrimary);
        border-radius: 24px;
        width: 100%;

        .titleGroup {
          margin-bottom: 30px;
          .title {
            color: var(--titleColor);
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 4px;
          }
          .description {
            color: var(--subTitleColor);
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
          }
        }

        .form {
          .c__input {
          }

          & > button {
            cursor: pointer;
            padding: 16px 20px;
            height: 54px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.3px;
            text-transform: uppercase;

            margin: 10px 0px 0px 0px;
          }
        }
      }

      .errorMessageOTP {
        color: var(--errorColor);
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        margin-top: 10px;
      }

      .resendCodeHint {
        color: var(--subTitleColor);
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        margin-top: 24px;
      }

      .resendCodeContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 24px;
        margin-top: 24px;
      }

      .resendCode {
        display: flex;
        align-items: center;
        & > p {
          font-weight: 500;
          cursor: pointer;
          color: var(--primaryColor);
          &.resendCodeDisabled {
            pointer-events: none;
            color: var(--labelColor);
          }
        }
        .loaderResend {
          display: inline-block;
          width: 28px;
          height: 28px;
          & > img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }

      .loadingOTP {
        margin-top: 10px;
        height: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        .loader {
          width: 32px;
          height: 32px;
          & > img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
        & > p {
          color: var(--titleColor);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}
