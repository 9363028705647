.levelsBreakdownListPage {
  .levelsBreakdownList {
    .levelsBreakdownList__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 8px;

          & svg {
            width: 22px;
            height: 22px;
          }

          .c__input-search {
            min-width: 478px;
          }

          .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            & .plusIcon {
              width: 22px;
              height: 22px;
              & > img {
                display: block;
                width: 100%;
                object-fit: contain;
              }
            }

            & p {
              color: var(--titleColor);
              font-size: 16px;
              font-weight: 500;
              line-height: 140%; /* 22.4px */
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }
      &-content {
        margin-top: 24px;
        .app-table {
          & .table-header-item:first-child {
            width: 80px !important;
          }
          & .table-header-item:nth-child(2) {
            min-width: 250px;
          }
          .buttonGroupsContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .buttonGroups {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 60px;
            column-gap: 8px;
            & div {
              padding: 3px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              & svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
