.voucherList {
  &__table {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }

      &--actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
        .c__select {
          padding: 0;
        }
        & svg {
          width: 22px;
          height: 22px;
        }
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
      }
    }

    &__content {
      margin-top: 24px;
      .app-table {
        & .table-header-item:last-child {
          width: 10% !important;
        }

        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 12px;
        }
      }
    }

    .voucher-code-cell {
      display: flex;
      align-items: center;
      gap: 12px;

      span {
        width: 90px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: '4px';
      }

      svg {
        cursor: pointer;
      }

      .c__copy-button-container {
        .c__copy-button > svg {
          stroke: var(--titleColor);
        }
      }
    }
  }
}
