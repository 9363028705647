.responsible_person_route {
  .contact_details {
    .card-content {
      display: grid;
      gap: 24px;

      .contact_details-main-content {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .icon {
          width: 30px;
          height: 30px;
          padding: 3px;
          cursor: pointer;
          & svg {
            width: 24px;
            height: 24px;
            display: block;
            object-fit: contain;
          }
        }

        .content {
          display: grid;
          grid-template-columns: repeat(6, minmax(0, 1fr));
          column-gap: 24px;

          .item {
            grid-column: span 2;

            .c__input {
              padding-bottom: 0px;
            }

            .c__phoneNumberInput-wrapper {
              padding-bottom: 0px;
            }
          }

          .item_custom {
            grid-column: span 3;
          }

          .padY {
            padding-bottom: 24px;
          }

          .custom_show_phone {
            display: flex;
            justify-content: space-between;
            align-items: center;

            word-break: normal;

            gap: 12px;

            & > button {
              aspect-ratio: 1;
              padding: 0;

              display: flex;
              justify-content: center;
              align-items: center;

              border: none;

              margin-right: auto;

              & > svg {
                min-width: 20px;
                min-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
