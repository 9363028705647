.reportOutstanding {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__search {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;

    &__fields {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
    }

    & > .btn {
      margin-bottom: 12px;
    }
  }

  &__content {
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    padding: 24px;
    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
    }

    &__table {
      margin-top: 24px;
      overflow-x: auto !important;
      position: relative;
      &::-webkit-scrollbar {
        height: 5px !important;
      }
    }
  }
}
