.level__assessmentScore {
  border-radius: 8px;
  background: var(--bgTertiary);
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 20px;

  &-title {
    color: var(--titleColor);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
    text-transform: uppercase;
  }

  &-score {
    &-title {
      color: var(--titleColor);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      margin-bottom: 4px;
    }

    &-form {
      border-radius: 8px;
      border: 1px solid var(--lineColor);
      height: 54px;

      display: flex;
      align-items: center;
      overflow: hidden;

      &-input {
        height: 100%;
        width: 100%;
        background-color: transparent;
        outline: none;
        padding: 16px 12px;
        border-right: 1px solid var(--lineColor);

        color: var(--titleColor);
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 54px;
        padding: 16px;
        transition: all 0.3s;
        cursor: pointer;

        & > svg,
        img {
          display: block;
          object-fit: contain;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &-scoreDetails {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &-form {
      border-radius: 8px;
      height: 54px;
      border: 1px solid var(--lineColor);
      display: flex;
      align-items: center;
      overflow: hidden;

      &-remove {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 100%;
        padding: 16px;
        border-right: 1px solid var(--lineColor);
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          border-color: var(--errorColor);
          background-color: var(--errorColor);
        }

        & > svg,
        img {
          display: block;
          object-fit: contain;
          width: 22px;
          height: 22px;
          flex: none;
        }
      }

      &-input {
        height: 100%;
        width: 100%;
        background-color: transparent;
        outline: none;
        padding: 16px 12px;
        border-right: 1px solid var(--lineColor);

        color: var(--titleColor);
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; /* 22.4px */

        &[type='text'] {
          flex: 1;
        }
        &[type='number'] {
          max-width: 54px;
          text-align: center;
        }
      }

      &-of {
        flex: none;
        text-align: center;
        width: 52px;
        height: 100%;
        padding: 16px;
        border-right: 1px solid var(--lineColor);
        background: var(--lineColor);
      }
      &-maxScore {
        width: 54px;
        height: 54px;
        background-color: transparent;
        outline: none;
        padding: 16px;
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        text-align: center;
      }
    }
  }

  &-actions {
    &-addMore {
    }
  }
}
