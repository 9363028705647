.notificationTemplateList {
  &__table {
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    &--header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
        white-space: nowrap;
      }
      &--search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        & svg {
          width: 22px;
          height: 22px;
        }
        .c__input-search {
          min-width: 290px;
        }
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & .plusIcon {
            width: 22px;
            height: 22px;
          }
          & p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            white-space: nowrap;
          }
        }
      }
    }
    &--content {
      margin-top: 24px;
      .app-table {
        .table-header-item:last-child {
          width: 5% !important;
        }
        & .table-content-item {
          & > td:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .buttonGroupsContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 60px;
          column-gap: 8px;
          & div {
            padding: 3px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            & svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  &__ellipsisVertical {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s;

    position: relative;
    padding: 0;
    border: none;

    & svg {
      width: 24px;
      height: 24px;
      padding: 3px;
      border-radius: 4px;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        background-color: var(--primaryColor);
      }

      &.open {
        background-color: var(--primaryColor);
      }
    }

    .viewDetailPopup {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 12;

      border-radius: 8px;
      border: 1px solid var(--lineColor);
      background: var(--bgPrimary);
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

      padding: 8px 0px;

      &-item {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
        padding: 10px 24px;
        cursor: pointer;
        text-transform: none;
      }

      &-horizontal {
        height: 1px;
        width: 100%;
        background-color: var(--lineColor);
        margin: 8px 0px;
      }
    }
  }
}
