.responsible_person_route {
  .summary-info {
    padding: 30px 30px 0 30px;
    margin-top: 0px;

    .card-content {
      position: relative;
    }

    .pageLoading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      padding: 50px 30px;

      &-main {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        & > span > span {
          background-color: var(--primaryColor) !important;
        }
      }
    }

    .c__modal {
      .c__modal-main {
        width: 500px;

        .c__modal-formTitle {
          color: var(--titleColor);
          text-align: center;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 44.8px */
          text-transform: uppercase;
        }

        .c__modal-formContent {
          width: 100%;

          .options {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }

          .item {
            display: flex;

            .c__input {
              padding-top: 44px;
              padding-bottom: 12px;
              width: 100%;
            }

            .c__textarea {
              width: 100%;
              padding-bottom: 0;
            }
          }
        }

        .c__modal-formActions {
          button {
            width: 100%;
          }
        }
      }
    }

    .moneyCreditHistory_btn {
      margin-left: auto;

      height: fit-content;

      border-radius: 8px !important;
      border: 1px dashed var(--lineColor) !important;

      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 4px;

      border-radius: 8px;
      border: none;
      background: var(--bgInputColor);

      .text {
        color: var(--subTitleColor);
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }

      .summary-info-item_label {
        color: var(--infoColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }

      .summary-info-item_value {
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .updateModalForm__content {
      min-height: 48px;
      height: 48px;

      .light__app-table {
        margin-top: 20px;
        margin-bottom: 24px;

        min-height: unset;
        .light__content-table {
          display: none !important;
        }

        .light__results-per-page,
        .light__table-pagination {
          margin-top: 0px !important;
        }
      }

      .moneyCreditHistory {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          align-items: center;
          gap: 12px;

          padding: 12px 0;
          border-bottom: 1px solid var(--lineColor);

          .tag {
            flex: none;
            display: flex;
            width: 40px;
            height: 40px;
            padding: 8px;
            justify-content: center;
            align-items: center;

            border-radius: 20px;
            background: var(--primaryColor);
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .tittle {
              color: var(--titleColor);
              font-size: 14px;
              font-weight: 500;
              line-height: normal;

              text-transform: capitalize;

              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 4px;

              .ct_text {
                text-transform: none;
                color: var(--titleColor);
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
              }
            }

            .date {
              color: var(--subTitleColor);
              font-size: 12px;
              font-weight: 400;
              line-height: normal;
            }
          }

          .moneyText {
            margin-left: auto;
            width: max-content;
            min-width: 100px;

            text-align: right;

            font-size: 16px;
            font-weight: 500;
            line-height: normal;
          }

          .green {
            color: var(--lightGreenColor);
          }

          .red {
            color: var(--lightPink);
          }
        }
      }
    }

    .summary-info_header {
      display: flex;
      justify-content: space-between;

      padding-bottom: 10px;

      .summary-info_wrap {
        display: flex;

        .summary-info_name {
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 39.2px */
          text-transform: uppercase;
        }
      }

      .summary-info_button {
        display: flex;
        justify-content: flex-end;
        gap: 24px;

        cursor: pointer !important;

        & > button {
          display: flex;
          padding: 10px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;

          border-radius: 8px;
          border: 1px solid var(--titleColor);
          background: #1c1c24;

          color: var(--titleColor);
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.3px;
          text-transform: uppercase;

          cursor: pointer !important;
        }
      }
    }

    .summary-info_wrap {
      display: flex;
      gap: 20px;
      text-transform: uppercase;
      font-size: 14px;
      justify-content: space-between;
      align-items: flex-end;
    }

    .summary-info_wrap-content {
      display: flex;
      align-items: center;
      gap: 12px;

      .status_wrap {
        display: flex;
        gap: 10px;

        .status {
          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 10px;
          padding: 4px 6px 5px 6px;
          width: max-content;

          & > div {
            color: #fff;
            font-size: 8px;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }

    .summary-info-status {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 10px;
      padding: 4px 6px 5px 6px;
      margin-right: 10px;

      & > div {
        font-size: 8px;
        line-height: 8px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .summary-info-item {
      display: flex;
      align-items: center;
      gap: 8px;

      .summary-info-item_label {
        color: var(--infoColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }

      .summary-info-item_value {
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }
}
