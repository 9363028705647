$animation-speed: 0.3s;

.levelAgeInput {
  position: relative;
  padding-top: 20px;
  padding-bottom: 12px;
}

.levelAgeInput-field {
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  border: 1px solid var(--lineColor);
  border-radius: 8px;

  font-size: 16px;
  color: var(--titleColor);
  font-weight: 400;
  line-height: 140%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;

  background: transparent;
  transition: border-color $animation-speed;

  &-large {
    height: 54px;
    padding: 16px 20px;
    padding-right: 80px;
  }
  &-small {
    height: 42px;
    padding: 10px 20px;
    padding-right: 80px;
  }

  &-append-right {
    padding-right: 36px;
  }
  &-disabled {
    background: var(--bgTertiary);
  }
}
.levelAgeInput-field.levelAgeInput-border {
  border: 1px solid var(--lineColor);
  &-error {
    border-color: var(--errorColor) !important;
  }
  &-success {
    border-color: var(--successColor) !important;
  }
  &-caption {
    border-color: var(--titleColor) !important;
  }
}

.levelAgeInput-field::placeholder {
  color: transparent;
}

.levelAgeInput-label {
  transition: all $animation-speed;
}

.levelAgeInput-field:focus {
  border: 1px solid var(--primaryColor);
}

.levelAgeInput-field:placeholder-shown ~ .levelAgeInput-label {
  cursor: text;
  color: var(--placeholderColor);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.levelAgeInput-field:not(:placeholder-shown) ~ .levelAgeInput-label-noFloating {
  display: none;
}

.levelAgeInput-label,
.levelAgeInput-field:focus ~ .levelAgeInput-label {
  position: absolute;
  top: 6px;
  transform: translateY(-140%);
  left: 0;
  display: block;

  color: var(--titleColor);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.5px;
  &-noFloating {
    display: none;
  }
}

.levelAgeInput-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 22px;
  height: 22px;
  transform: translateY(-50%);

  & svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
}

.levelAgeInput-message {
  margin-top: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 140%;

  &-error {
    color: var(--errorColor);
  }
  &-success {
    color: var(--successColor);
  }
  &-caption {
    color: var(--titleColor);
  }
}

.levelAgeInput__select {
  z-index: 10;
  position: absolute;
  cursor: pointer;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  &__container {
    display: flex;
    align-items: center;
    column-gap: 4px;
    & > p {
      color: var(--titleColor);
      text-align: right;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
    }

    &.open {
      & > svg {
        transform: rotate(180deg);
      }
    }
  }
  &__popper {
    position: absolute;
    top: 150%;
    left: -50%;
    z-index: 10;
    min-width: 140px;

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

    padding: 8px 0px;

    &--item {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      white-space: nowrap;
      padding: 10px 24px;
      cursor: pointer;
      text-transform: capitalize;
    }
  }
}
