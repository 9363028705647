.auditLogListPage {
  // MAIN || LIST
  .auditLogList {
    .auditLogList__table {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 18px;

          .searchContainer {
            position: relative;

            .c__input-search {
              min-width: 478px;
              .arrowIcon {
                transition: all 0.3s;
                cursor: pointer;
                &.active {
                  transform: translateY(-10%) rotate(180deg);
                }
              }
            }

            .searchComponent {
              position: absolute;
              top: calc(100% + 2px);
              left: 0;

              display: none;
              grid-template-columns: 1fr 1fr;
              column-gap: 24px;

              background: var(--bgPrimary);
              border-radius: 8px;
              border: 1px solid var(--lineColor);
              padding: 24px;
              width: 100%;

              z-index: 12;

              &.active {
                display: grid;
              }

              & .c__input {
                grid-column: span 2 / span 2;
              }

              & .c__datePicker_wrapper {
              }

              &__actions {
                grid-column: span 2 / span 2;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                column-gap: 24px;
              }
            }
          }

          // EXPORT BUTTON
          & > .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;

            color: var(--titleColor);
            font-size: 16px;
            font-weight: 400;
            line-height: 140%; /* 22.4px */

            padding: 10px;
            border-color: var(--lineColor);
            text-transform: capitalize;

            & svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      &-content {
        margin-top: 24px;
        .app-table {
          .buttonGroups {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 12px;
            .ellipsisVertical {
              display: flex;
              justify-content: center;
              align-items: center;

              transition: all 0.3s;

              position: relative;
              padding: 0;
              border: none;

              & svg {
                width: 24px;
                height: 24px;
                padding: 3px;
                border-radius: 4px;
                background-color: transparent;
                cursor: pointer;

                &:hover {
                  background-color: var(--primaryColor);
                }
              }

              .viewDetailPopup {
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 10;

                white-space: nowrap;
                border-radius: 8px;
                border: 1px solid var(--lineColor);
                background: var(--bgPrimary);
                box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);
                padding: 18px 24px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .createAuditLogModal {
    .c__modal-main {
      width: 60vw;
      .c__modal-formTitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;
        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }

        & > svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .c__modal-formContent {
        width: 100%;
        .c__card {
          row-gap: 30px;
          .c__card-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            margin-top: -14px;

            .custom_show_phone {
              display: flex;
              justify-content: space-between;
              align-items: center;

              word-break: normal;

              gap: 12px;

              & > button {
                aspect-ratio: 1;
                padding: 0;

                display: flex;
                justify-content: center;
                align-items: center;

                border: none;

                margin-right: auto;

                & > svg {
                  min-width: 20px;
                  min-height: 20px;
                }
              }
            }
          }
          .app-table {
            margin-top: -14px;
          }
        }
      }
    }
  }

  .updateAuditLogModal {
    .c__modal-main {
      width: 60vw;
      .c__modal-formTitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;

        padding-bottom: 24px;

        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }

        & > svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .c__modal-formContent {
        width: 100%;
      }
    }

    &-content {
      border-radius: 8px;
      border: 1px solid var(--lineColor);

      &-info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        background-color: var(--bgTertiary);
        padding: 24px;
      }

      &-changedFields {
        &__block {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          padding: 24px;

          &-items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;

            .c__card-content-item-sub {
              text-transform: capitalize;
            }

            &-emergencyContact {
              grid-column: span 3 / span 3;
              &-block {
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                margin-bottom: 24px;
                &:last-child {
                  margin-bottom: 0px;
                }
                &-items {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  gap: 24px;
                }
              }
            }
          }

          &-old {
          }
          &-new {
            & .c__card-content-item-title {
              color: var(--errorColor);
            }
          }
        }

        &__horizontal {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          // Circle Icon
          & > div {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid var(--labelColor);
            padding: 6px;
            z-index: 10;
            background-color: var(--bgPrimary);
          }
          // Horizontal line
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: var(--lineColor);
          }
        }
      }
    }
  }
}
