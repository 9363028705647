.c__toasts-container {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 12px;
  position: fixed;
  z-index: 150;
  // DEFAULT - TOP/RIGHT
  top: 16px;
  right: 16px;
}

.c__toast {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 464px;
  height: 72px;
  padding: 24px;
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid var(--lineColor);
  background: var(--bgPrimary);

  animation: slide-in 0.4s ease-in-out forwards;
  &-dismissed {
    animation: slide-out 0.4s ease-in-out forwards;
  }

  &-title {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      & > div {
        width: 16px;
        height: 16px;
        & > img {
          display: block;
          width: 100%;
          object-fit: contain;
        }
      }

      &-success {
        background-color: var(--successColor);
      }
      &-warning {
        background-color: var(--warningColor);
      }
      &-error {
        background-color: var(--errorColor);
      }
      &-info {
        background-color: var(--primaryColor);
      }
    }

    &-message {
      color: var(--titleColor);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
  }

  // PROGRESS
  &-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: transparent;
    &-bar {
      height: 100%;
      animation: progress-bar 4s linear forwards;
      &-success {
        background-color: var(--successColor);
      }
      &-warning {
        background-color: var(--warningColor);
      }
      &-error {
        background-color: var(--errorColor);
      }
      &-info {
        background-color: var(--primaryColor);
      }
    }
  }

  &-dismissedButton {
    cursor: pointer;
    width: 24px;
    height: 24px;
    & > svg {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes progress-bar {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
