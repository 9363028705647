.reportAbandonedCartList__content {
  border-radius: 8px;
  border: 1px solid var(--lineColor);
  background: var(--bgPrimary);
  padding: 24px;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &--title {
      color: var(--titleColor);
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-transform: uppercase;
    }
  }

  &__table {
    .app-table {
      margin-top: 24px;
      overflow-x: auto !important;
    }
  }
}
