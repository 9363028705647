.filter-component {
  background-color: var(--bgPrimary);
  right: 0;
  top: 84px;
  border-left: 1px solid var(--lineColor);
  border-bottom: 1px solid var(--lineColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 85px);
  z-index: 12;
  width: 380px;

  position: fixed;
  left: 100%;

  transition: all 0.3s;
  overflow: hidden;

  &.open {
    left: calc(100vw - 380px);
  }
}
// .filter-component.open {
//   width: 30%;
//   transition: all 0.3s ease-in;
// }
.filter-title {
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 8px;
  font-weight: 500;
}
.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.reset-label {
  color: var(--primaryColor);
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
}
.filter-body {
  padding: 24px;
}
.apply-btn {
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  white-space: nowrap;
}
.footer-filter {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}
.header-day {
  text-transform: uppercase;
  font-size: 12px;
  border-right: 1px solid var(--lineColor);
  border-bottom: 1px solid var(--lineColor);
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 10px;
  text-align: center;
  height: 34px;
  &.first-cell {
    width: 35px;
    border-top-left-radius: 8px;
    border: 1px solid var(--lineColor);
  }
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  width: calc(100% - 6px);
}
.calendar-grid-content {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
  overflow-y: scroll;
  height: calc(100vh - 216px - 100px);
  &-day {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
    background-color: var(--bgPrimary);
  }
}
.calendar-grid-content-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
}

.calendar-day {
  font-size: 12px;
  height: 140px;

  .level-item {
    border-radius: 0px 4px 0px 0px;
    border-top: 1px solid var(--lineColor);
    border-right: 1px solid var(--lineColor);
    padding: 2px 8px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    .text {
      span {
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        fill: var(--errorColor);
      }
      &.more {
        font-weight: 500;
      }
    }
  }
  .active {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
}
.date-text-month {
  margin: 3px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  .date-text {
    height: auto;
  }
}

.date-text.today {
  background-color: var(--primaryColor);
  font-weight: bold;
  width: 20px;
  border-radius: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}
.date-text {
  margin: 3px 0;
  font-size: 12px;
  text-align: center;
  height: 20px;
  cursor: pointer;

  .today {
    font-size: 26px;
    background: blue;
    border-radius: 99px;
    width: 40px;
    margin: auto;
    margin-top: 5px;
  }
}
.month-view-class {
  background-color: #123056;
  margin-top: 2px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 3px solid #006de6;
  box-sizing: border-box;
  text-align: left;
  padding: 6px;
  white-space: nowrap;
  width: 95%;
}
.calendar-day-container {
  position: relative;
  border-right: 1px solid var(--lineColor);
  border-bottom: 1px solid var(--lineColor);
  height: 150px;
  min-width: 75px;
  padding: 1px 8px 8px 1px;
  &.last {
    border-bottom-right-radius: 15px;
  }
  &.month {
    height: 170px;
  }
}
.group-class-popup {
  border: 1px solid var(--lineColor);
  border-radius: 15px;
  background: var(--bgSecondary);
  z-index: 1;
  padding: 12px;
  min-width: 181px;
  .close-icon {
    position: absolute;
    right: 5%;
    top: 10px;
  }
  &-title {
    font-size: 20px;
  }
  .class-list-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
    margin-top: 20px;
    .class-item {
      min-width: 115px;
      cursor: pointer;
      border: 1px solid var(--lineColor);
      border-radius: 8px;
      padding: 8px;
      padding-bottom: 12px;
      text-align: center;
      background-color: var(--primaryColor);
      &-title {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 16px;
      }
      &-description {
        font-size: 10px;
        line-height: 12px;
        margin-top: 5px;
      }
    }
  }
}
.calendar-day-popup {
  border: 1px solid var(--lineColor);
  border-radius: 15px;
  background: var(--bgSecondary);
  z-index: 1;
  padding: 12px;
  min-width: 181px;
  .close-icon {
    position: absolute;
    right: 5%;
    top: 10px;
  }
  .month-view-class .text {
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
    cursor: pointer;
  }
}
.class-details-content {
  div svg {
    // display: block;
    color: var(--bgPrimary);
    stroke-width: 2px;
    stroke: var(--bgPrimary);
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
  }
  div svg path {
    fill: var(--bgPrimary);
  }
  .calendar-day-popup {
    color: var(--titleColor);
  }
}
.schedule-wrapper {
  position: relative;
  resize: horizontal;
  min-width: 130px;
  max-width: 300px;
  width: 220px;
  .border-left {
    position: absolute;
    height: calc(100vh - 286px);
    width: 1px;
    left: 0;
    top: 0;
    &.hovered {
      cursor: ew-resize;
    }
  }
}
.calendar-wrapper {
  display: flex;
  gap: 16px;
  position: relative;
  &-left {
    width: 100%;
    background-color: var(--bgPrimary);
    .calendar-scroll-week {
      background: var(--bgPrimary);
      border-bottom-left-radius: 15px;
    }
  }
  &-right {
    display: flex;
    overflow: hidden;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);
    height: calc(100vh - 283px);
    .scrolling-column {
      width: 100%;
    }
    .schedule-by-level {
      overflow-y: scroll;
      height: calc(100vh - 383px);
      width: 100%;
    }

    .date-info {
      width: 100%;
      margin-bottom: 16px;
      &-header {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        border-left: none;
        cursor: pointer;
        min-width: 75px;
      }
    }
    .time-text {
      font-size: 14px;
      margin-bottom: 8px;
      font-weight: 500;
    }
    .schedule-column {
      width: 100%;
      gap: 2px;
      display: flex;
      flex-direction: column;
      .level-item {
        cursor: pointer;
        display: flex;
        height: 32px;
        padding: 2px 8px;
        border-radius: 0px 4px 0px 0px;
        border-top: 1px solid #40404a;
        font-size: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        text-transform: uppercase;
        &-wrapper {
          border-right: 1px solid #40404a;
          border-radius: 0px 4px 0px 0px;
        }
        .active {
          width: 4px;
          height: 4px;
          border-radius: 4px;
        }
      }
      .list-class-animation {
        // transition: all 0.5s ease-in;
        height: 0;
        // width: 0;
        overflow: hidden;
        &.active {
          // width: 100%;
          height: 100%;
          transition: all 0.8s ease-out;
        }
      }
      .list-class-wrapper {
        // display: flex;
        padding: 6px 4px;
        .class-item {
          display: flex;
          padding: 8px;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          border-radius: 8px;
          gap: 4px;
          width: 100%;
          margin-bottom: 4px;
          &-title {
            font-size: 12px;
            font-weight: 500;
          }
          &-description {
            font-size: 10px;
          }
        }
      }
    }
  }
}
.week-calendar {
  display: flex;
  .date-in-week {
    width: 100%;
  }
  &.content {
    display: flex;
    overflow-y: scroll;
    width: calc(100% + 4px);
    height: calc(100vh - 216px - 168px);
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: var(--bgSecondary);
    }
  }
  .time-wrapper {
    // width: 37px;
    z-index: 0;
    // border: 1px solid transparent;
    // border-right: none;
    position: relative;
    .line-content {
      border: none;
    }
    .time-item {
      width: 35px;
      border-left: 1px solid var(--lineColor);
      &.last {
        border-bottom-left-radius: 15px;
        border: 1px solid var(--lineColor);
      }
    }
    .header-day.first-cell {
      border: none;
      border-top-left-radius: 8px;
    }
  }
}
