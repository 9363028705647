.c_footer-app {
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-height: 51px;
  background: var(--bgPrimary);
  z-index: 11;
  border-top: 0.5px solid var(--lineColor);
  padding: 15px 33px 15px 30px;
  font-size: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  overflow: hidden;

  &_content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &_btn {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .btn_start {
    -webkit-transition: opacity 0.6s ease-out;
    -moz-transition: opacity 0.6s ease-out;
    -ms-transition: opacity 0.6s ease-out;
    -o-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;

    opacity: 0;
  }

  .btn_end {
    -webkit-transition: opacity 0.6s ease-in;
    -moz-transition: opacity 0.6s ease-in;
    -ms-transition: opacity 0.6s ease-in;
    -o-transition: opacity 0.6s ease-in;
    transition: opacity 0.6s ease-in;

    opacity: 1;
  }
}

.height_start {
  -webkit-transition: max-height 0.5s ease-out;
  -moz-transition: max-height 0.5s ease-out;
  -ms-transition: max-height 0.5s ease-out;
  -o-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;

  max-height: 0;
}

.height_end {
  -webkit-transition: max-height 0.5s ease-in;
  -moz-transition: max-height 0.5s ease-in;
  -ms-transition: max-height 0.5s ease-in;
  -o-transition: max-height 0.5s ease-in;
  transition: max-height 0.5s ease-in;

  max-height: 80px;
}
