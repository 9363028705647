.summary-card {
  display: flex;
  flex-direction: column;
  align-self: stretch;

  padding: 20px 20px 12px 20px;
  border: 1px solid var(--lineColor);
  border-radius: 8px;
  flex: 1;
  background-color: var(--bgPrimary);

  .summary-card_title {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 2px;
    font-weight: 500;
  }

  .summary-card_label {
    font-weight: 500;
    font-size: 12px;
    color: var(--subTitleColor);
  }

  .summary-card_value {
    margin-top: auto;

    font-size: 48px;
    line-height: normal;
    font-weight: 700;
    padding-top: 8px;
  }

  .summary-card-wrap {
    display: flex;
    justify-content: space-between;
  }
}
