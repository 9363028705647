.c__modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
height: 100svh;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background: rgba(0, 0, 0, 0.5);

  &-keepMounted {
    &-close {
      opacity: 0;
      visibility: hidden;
    }
    &-open {
      opacity: 1;
      visibility: visible;
    }
  }

  &-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 8px;
    border: 1px solid var(--lineColor);
    background: var(--bgPrimary);

    min-width: 500px;

    min-height: 100px;
  }

  &-title {
    padding: 24px 24px 12px 24px;
    &,
    * {
      color: var(--titleColor);
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
      text-transform: uppercase;
    }
  }

  &-content {
    padding: 12px 24px 48px 24px;
    &,
    * {
      color: var(--titleColor);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      text-transform: uppercase;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 24px;
    border-top: 1px solid var(--lineColor);
    & * {
      flex: 1 1 0px;
    }
  }

  &-formTitle {
    padding: 24px 24px 12px 24px;
    &,
    * {
      color: var(--titleColor);
      text-align: left;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      text-transform: uppercase;
    }
  }

  &-formContent {
    margin: 12px 0px 24px;
    padding: 0px 24px;
    // padding: 12px 24px 24px 24px;
    width: 800px;
    max-height: 70vh;
    overflow-y: auto;
  }

  &-formActions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    padding: 22px 24px;
    border-top: 1px solid var(--lineColor);

    & * {
      width: 108px;
    }
  }
}
