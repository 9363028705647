.c__comingSoon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100px;
  padding: 30px 0;

  .text {
    margin-top: 10px;

    color: var(--titleColor);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    letter-spacing: 12px;
    text-transform: uppercase;
  }

  .sub_text {
    margin-top: 30px;

    color: var(--titleColor);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}
