@import 'index.module.scss';
.settings-component {
  background-color: var(--bgPrimary);
  right: 0;
  top: 84px;
  border-left: 1px solid var(--lineColor);
  border-bottom: 1px solid var(--lineColor);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 85px);
  z-index: 12;
  width: 312px;

  position: fixed;
  left: 100%;

  transition: all 0.3s;
  overflow: hidden;

  &.open {
    left: calc(100vw - 312px);
  }
  .pick-color-container {
    position: fixed;
    right: 312px;
    top: 154px;
  }
}
.settings-title {
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 8px;
}
.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.reset-label {
  color: var(--primaryColor);
  font-size: 16px;
  cursor: pointer;
}
.settings-body {
  padding: 24px;
  border-bottom: 1px solid var(--lineColor);
}
.colors-body {
  padding: 24px;
  .color-setting-list {
    overflow-y: scroll;
    height: calc(100vh - 300px);
  }
}

.apply-btn {
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  white-space: nowrap;
}
.colorSettings-content {
  background-color: var(--bgSecondary);
  border-radius: 8px;
  position: relative;
  min-width: 100px;
  font-size: 14px;
  border: 1px solid var(--lineColor);
  .content {
    box-sizing: border-box;
    text-align: left;
    padding: 6px 10px;
    white-space: nowrap;
    left: 0;
    top: 0;
    text-transform: uppercase;
    user-select: none;
  }
  overflow: hidden;
}
.color-setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.color-setting-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 12px;
}
.color-setting-item-right {
  display: flex;
  justify-content: center;
  align-items: center;
  .color-box {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid var(--lineColor);
  }
}
