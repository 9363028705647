.level__skill__add {
  &__form {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 12px;
    & > .c__input {
      grid-column: span 3 / span 3;
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid var(--lineColor);
      margin-top: 20px;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        border-color: var(--errorColor);
        background-color: var(--errorColor);
      }

      & > svg,
      img {
        display: block;
        object-fit: contain;
        width: 24px;
        height: 24px;
      }
    }
  }
  &__milestones {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 12px;
  }
}
