.bulkManagementClassList {
  .classes-main {
    .classes-table_header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      gap: 24px;
      div {
        button {
          padding: 10px 12px 10px 20px;
          white-space: nowrap;
        }
      }
      p {
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
      }
    }

    .classes-table_header_right {
      display: flex;
      gap: 10px;
    }
    .classes-table-content-selected {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
      text-transform: uppercase;
    }
  }

  .content-cell {
    display: flex;
    align-items: center;
  }

  .btnHeaderLabel {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    text-transform: capitalize;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .btnActionHeaderClass {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 42px;
  }

  .classes-btn-bulk-action {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    .ellipsisVertical {
      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s;

      position: relative;
      padding: 0;
      border: none;

      & svg {
        width: 24px;
        height: 24px;
        padding: 3px;
        border-radius: 4px;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          background-color: var(--primaryColor);
        }
      }

      .viewDetailPopup {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;

        border-radius: 8px;
        border: 1px solid var(--lineColor);
        background: var(--bgPrimary);
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

        padding: 8px 0px;

        &-item {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          white-space: nowrap;
          padding: 10px 24px;
          cursor: pointer;
          text-transform: none;
        }

        &-horizontal {
          height: 1px;
          width: 100%;
          background-color: var(--lineColor);
          margin: 8px 0px;
        }
      }
    }
  }
}
