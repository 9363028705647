.student_route {
  .responsible-person-information {
    .card-content {
      .responsible-person-information-main-content {
        display: flex;
        gap: 24px;

        .content {
          display: flex;
          flex-direction: column;
          gap: 24px;

          width: 100%;

          .content_grid {
            display: grid;
            grid-template-columns: repeat(6, minmax(0, 1fr));
            gap: 20px;

            width: 100%;

            .item {
              grid-column: span 2;
            }

            .item_custom {
              grid-column: span 3;
            }
          }

          .content_tittle {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            gap: 12px;

            & > button > div {
              width: max-content;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              letter-spacing: 0.3px;
              text-transform: uppercase;
            }
          }
        }
      }

      .responsible-person-information-sub-content {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: 24px;

        margin-top: 24px;

        .item {
          grid-column: span 2;
        }

        .item_custom {
          grid-column: span 3;
        }

        .item_full {
          grid-column: span 6;
        }

        .custom_show_phone {
          display: flex;
          justify-content: space-between;
          align-items: center;

          word-break: normal;

          gap: 12px;

          & > button {
            aspect-ratio: 1;
            padding: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            border: none;

            margin-right: auto;

            & > svg {
              min-width: 20px;
              min-height: 20px;
            }
          }
        }
      }
    }
  }
}
