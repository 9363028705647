#reportListPage {
  .reportList {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .reportCard {
      border-radius: 8px;
      border: 1px solid var(--lineColor);
      background: var(--bgPrimary);
      padding: 24px;
      &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        &--name {
          flex: 1;
          color: var(--titleColor);
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          text-transform: uppercase;
        }
        &--button {
          padding: 10px;
          & > a {
            border-radius: 8px;
            border: 1px solid var(--bgSecondary);
            background: var(--bgSecondary);
            padding: 6px 12px;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 130px;
            height: 32px;
            color: var(--titleColor);
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
              border-color: var(--bgTertiary);
              background-color: var(--bgTertiary);
            }
          }
        }
      }
    }
  }
}
