.activityListPage {
  // MAIN || LIST
  .activityList {
    .activityList__main {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        &-title {
          color: var(--titleColor);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-transform: uppercase;
        }

        &-search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 18px;

          .searchContainer {
            position: relative;

            .c__input-search {
              min-width: 478px;
              .arrowIcon {
                transition: all 0.3s;
                cursor: pointer;
                &.active {
                  transform: translateY(-10%) rotate(180deg);
                }
              }
            }

            .searchComponent {
              position: absolute;
              top: calc(100% + 2px);
              left: 0;

              display: none;
              grid-template-columns: 1fr 1fr;
              column-gap: 24px;

              background: var(--bgPrimary);
              border-radius: 8px;
              border: 1px solid var(--lineColor);
              padding: 24px;
              width: 100%;

              z-index: 12;

              &.active {
                display: grid;
              }

              & .c__input {
                grid-column: span 2 / span 2;
              }

              & .c__datePicker_wrapper {
              }

              &__actions {
                grid-column: span 2 / span 2;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                column-gap: 24px;
              }
            }
          }
        }
      }
      &-blocks {
        margin-top: 24px;
      }

      &-block {
        &-horizontal {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            width: 100%;
            height: 1px;
            background-color: var(--lineColor);
          }
          & > p {
            text-align: center;
            color: var(--titleColor);
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
            text-transform: uppercase;

            background-color: var(--bgPrimary);
            padding: 4px 12px;
            border-radius: 24px;
            border: 1px solid var(--lineColor);
            width: fit-content;
            z-index: 10;
          }
        }
        &-items {
          padding-top: 24px;
          padding-bottom: 24px;
        }
        &-item {
          display: flex;
          flex-wrap: wrap;
          column-gap: 8px;
          align-items: flex-start;
          margin-bottom: 24px;
          & .c__avatar {
          }
          &-content {
            flex: 1;
            &-info {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 10px;

              margin-bottom: 2px;

              & * {
                color: var(--titleColor);
                font-size: 14px;
                font-style: normal;
                line-height: 140%; /* 19.6px */
                text-transform: uppercase;
              }

              &-title {
                font-weight: 500;
              }
              &-subtitle {
                font-weight: 400;
                color: var(--subTitleColor);
              }
              &-fill {
                display: block;
                text-decoration: none;
                font-weight: 400;
                border-radius: 4px;
                background: var(--primaryColor);
                padding: 2px 4px;
                &Secondary {
                  background-color: var(--subTitleColor);
                }
              }
            }
            &-time {
              .tooltip-trigger {
                display: inline-block;
                color: var(--subTitleColor);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 16.8px */
                text-transform: uppercase;
                &:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }

                & .tooltip {
                  transform: translateX(-40%);

                  &-top::after {
                    border-color: var(--primaryColor) transparent transparent
                      transparent;
                  }

                  .tooltip-text {
                    padding: 10px;
                    color: var(--titleColor);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 16.8px */
                    text-transform: initial;
                  }
                }
              }
            }

            &-forms {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 12px;
              background-color: var(--bgSecondary);
              border-radius: 8px;

              margin-top: 12px;
              padding: 24px;
            }
            &-form {
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 12px;

              padding: 12px 12px 12px 24px;
              background-color: transparent;
              border-radius: 8px;

              border: 1px solid var(--lineColor);

              & svg {
                height: auto;
                width: 24px;
                cursor: pointer;
              }

              &-title {
                flex: 1;
                &-name {
                  display: block;
                  text-decoration: none;
                  color: var(--titleColor);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 140%; /* 22.4px */
                  // margin-bottom: 4px;
                }
                &-size {
                  color: var(--subTitleColor);
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 140%; /* 14px */
                }
              }
            }
          }
        }
      }

      .c__loading__container {
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 12px;
      }
    }
  }
}
