.reportSelection {
  display: block;
  padding-top: 20px;
  padding-bottom: 12px;
  &Main {
    position: relative;
    &Label {
      position: absolute;
      left: 0;
      top: -2px;
      transform: translateY(-100%);
      color: var(--titleColor);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
    }
    &Input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      column-gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--lineColor);
      padding: 10px 10px 10px 16px;
      cursor: pointer;
      & > p {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
      & > svg {
        width: 16px;
        height: 16px;
      }
    }
    &Dropdown {
      z-index: 10;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--lineColor);
      background: var(--bgPrimary);
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);
      padding: 8px 0px;

      overflow-y: auto;
      max-height: 218px;

      &Search {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--lineColor);
        border-radius: 8px;

        margin: 4px 12px;

        & input {
          width: 100%;
          border-radius: 8px;
          padding: 6px 12px;
          background: transparent;
          border: none;
          outline: 0;
          height: 34px;
          font-size: 16px;
        }

        & svg {
          width: 16px;
          height: 16px;
          cursor: pointer;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      &Item {
        padding: 10px 16px;
        & > .c__checkbox {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
}
