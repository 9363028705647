.student_route {
  .medical_condition__wrapper {
    &__card {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
    }

    & svg {
      cursor: pointer;
    }
  }
  .document-link {
    cursor: pointer;
  }
}
