$size: 20px;
$gap: 12px;
$fontSize: 16px;

.c__radio {
  display: block;
  position: relative;
  padding-left: calc($size + $gap);
  cursor: pointer;

  color: var(--titleColor);
  font-size: $fontSize;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default radio button */
  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Create a custom radio button */
  &-checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: $size;
    width: $size;
    background-color: var(--bgPrimary);
    border-radius: 50%;
    border: 1px solid var(--lineColor);
    &-noLabel {
      transform: translateY(-50%);
    }
  }
}

/* On mouse-over, add a grey background color */
.c__radio:hover .c__radio-input ~ .c__radio-checkMark {
  background-color: var(--bgPrimary);
}

/* When the radio button is checked, add a blue background */
.c__radio .c__radio-input:checked ~ .c__radio-checkMark {
  background-color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.c__radio-checkMark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.c__radio input:checked ~ .c__radio-checkMark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.c__radio .c__radio-checkMark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc($size / 2);
  height: calc($size / 2);
  border-radius: 50%;
  background: var(--bgPrimary);
}
