.c__input-search {
  display: flex;
  border: 1px solid var(--lineColor);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  position: relative;

  input {
    width: 100%;
    border-radius: 8px;
    padding: 10px 12px 10px 20px;
    background: transparent;
    border: none;
    outline: 0;
    height: 42px;

    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
}

.c__input-search-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 22px;
  height: 22px;
  transform: translateY(-50%);

  & svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  &.withSuffix {
    right: 38px;
  }
}

.c__input-search-prefix {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  & svg {
    width: 22px;
    height: 22px;
  }
}

.c__input-search-suffix {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  & svg {
    width: 22px;
    height: 22px;
  }
}
