.student_route {
  .personal-information {
    .card-content {
      display: grid;
      gap: 24px;

      .personal-information-main-content {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .icon {
          width: 30px;
          height: 30px;
          padding: 3px;
          cursor: pointer;
          & svg {
            width: 24px;
            height: 24px;
            display: block;
            object-fit: contain;
          }
        }

        .content {
          display: grid;
          grid-template-columns: repeat(8, minmax(0, 1fr));
          column-gap: 24px;

          .item {
            grid-column: span 2;
          }

          .item_custom {
            grid-column: span 3;
          }

          .padY {
            padding-bottom: 24px;
          }
        }
      }
    }
  }
}
