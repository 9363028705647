.bulkManagementNotificationEditContainer {
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    z-index: 11;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: all 0.3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.bulkManagementNotificationEdit {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 12;

  display: flex;
  flex-direction: column;

  min-width: calc(100vw - 280px);
  height: 100vh;
  background-color: var(--bgPrimary);
  border: 1px solid var(--lineColor);
  transition: all 0.3s;
  overflow: hidden;

  &.active {
    left: 280px;
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 24px;
    border-bottom: 1px solid var(--lineColor);
    & svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    & > p {
      color: var(--titleColor);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      text-transform: uppercase;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    height: calc(100vh - 77px - 95px);
    overflow-y: auto;
  }
  .notification-status {
    width: fit-content;
    display: flex;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
    background: transparent;

    color: var(--titleColor);
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
    &-scheduled {
      background-color: var(--primaryColor);
    }
    &-sent,
    &-delivered {
      background-color: var(--warningColor);
    }
    &-canceled, &-failed {
      background-color: var(--errorColor);
    }
  }

  // INSIDE CONTENT
  &__info {
    & .c__card-header-title {
      margin-bottom: 20px;
    }
  }
  &__description {
    &--email {
      &--label {
        color: var(--titleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.5px;
      }
      &--error {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
        color: var(--errorColor);
      }
    }
  }
  &__table {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 24px;
      &--title {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }
      &--actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .c__input-search {
          min-width: 478px;
        }
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
      }
    }

    &__content {
      .app-table {
        & .table-header-item:first-child {
          width: 1% !important;
        }
      }
    }
  }

  // ACTIONS
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    padding: 20px 24px;
    border-top: 1px solid var(--lineColor);

    & .abortButton {
      background-color: var(--errorColor);
      border-color: var(--errorColor);
    }
  }
}
