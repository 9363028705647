.bulkManagementCreditList {
  .student__table,
  .history__table {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      &-title {
        color: var(--titleColor);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        text-transform: uppercase;
      }

      &-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 18px;

        .c__input-search {
          min-width: 478px;
        }

        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & > .plusIcon {
            width: 22px;
            max-width: 22px;
            height: 22px;

            display: flex;
            align-items: center;
            justify-content: center;

            & > img {
              display: block;
              width: 100%;
              object-fit: contain;
            }
          }

          & > p {
            color: var(--titleColor);
            font-size: 16px;
            font-weight: 500;
            padding-top: 2px;
            line-height: 14px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 12px;
          .ellipsisVertical {
            display: flex;
            justify-content: center;
            align-items: center;

            transition: all 0.3s;

            position: relative;
            padding: 0;
            border: none;

            & svg {
              width: 24px;
              height: 24px;
              padding: 3px;
              border-radius: 4px;
              background-color: transparent;
              cursor: pointer;

              &:hover {
                background-color: var(--primaryColor);
              }
            }

            .viewDetailPopup {
              position: absolute;
              top: 100%;
              right: 0;
              z-index: 10;

              border-radius: 8px;
              border: 1px solid var(--lineColor);
              background: var(--bgPrimary);
              box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.45);

              padding: 8px 0px;

              &-item {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                white-space: nowrap;
                padding: 10px 24px;
                cursor: pointer;
                text-transform: none;
              }

              &-horizontal {
                height: 1px;
                width: 100%;
                background-color: var(--lineColor);
                margin: 8px 0px;
              }
            }
          }
        }
      }
    }
    &-content {
      &-selected {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 19.6px;
        text-transform: uppercase;
      }
      .app-table {
        .buttonGroups {
          display: flex;
          align-items: center;
          column-gap: 12px;
          & div {
            cursor: pointer;
          }
        }
      }
    }

    .student-status {
      border-radius: 10px;
      display: inline-block;
      padding: 2px 10px;
      line-height: 2;
      margin-right: 10px;
      font-size: 8px;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .c__card {
    border-top-left-radius: 0px;
  }

  .btn-secondary {
    border-color: var(--lineColor);
  }
}
