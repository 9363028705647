.termsPage {
  .termAdditionFormContainer {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;

      z-index: 11;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transform: all 0.3s;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
    .termAdditionForm {
      position: fixed;
      top: 0;
      left: 100%;
      z-index: 12;

      display: flex;
      flex-direction: column;

      min-width: calc(100vw - 280px);
      height: 100vh;
      background-color: var(--bgPrimary);
      border: 1px solid var(--lineColor);
      transition: all 0.3s;
      overflow: hidden;

      &.active {
        left: 280px;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 24px;
        border-bottom: 1px solid var(--lineColor);

        & > div {
          width: 24px;
          height: 24px;
          cursor: pointer;
          & > img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
        }
        & > p {
          color: var(--titleColor);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }
      }

      & .c__card-header {
        margin-bottom: 16px;
      }

      &__content {
        overflow-y: auto;
        flex: 1;
        padding: 24px 24px 12px 24px;
        h3 {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 28px */
          text-transform: uppercase;
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .button-add-more {
            width: auto;
            max-width: 300px;
          }
          .item_single {
            padding: 20px 0;
            width: 54px;

            & > button {
              aspect-ratio: 1;
              width: 54px;
              padding: 0;

              display: flex;
              justify-content: center;
              align-items: center;

              & > svg {
                min-width: 24px;
                min-height: 24px;
              }
            }
          }
        }

        &-row {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 24px;
        border-top: 1px solid var(--lineColor);
        & > button {
          width: 120px;
          cursor: pointer;
        }
      }
    }
  }
  .termList {
    &-header {
      h3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        text-transform: uppercase;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      .search-term {
        display: flex;
        align-items: flex-start;
        gap: 17px;
        & svg {
          width: 22px;
          height: 22px;
        }

        .c__input-search {
          min-width: 420px;
        }
        .btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          & .plusIcon {
            width: 22px;
            height: 22px;
            & > img {
              display: block;
              width: 100%;
              object-fit: contain;
            }
          }

          & p {
            color: var(--titleColor);
            font-size: 16px;
            padding-top: 2px;
            line-height: 16px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            font-weight: 500;
          }
        }
      }
    }
    &-content {
      .app-table {
        .buttonGroupsContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .buttonGroups {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 60px;
          column-gap: 8px;
          & div {
            padding: 3px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            & svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}
