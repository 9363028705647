.c__modalPaymentWrapper {
  .c__loading__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 60;
  }
  .c__modalPayment {
    .paymentModal {
      &_content {
        .c__modal-main {
          width: 600px;
        }
      }
      &__errorInfo {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;

        width: fit-content;
        margin: auto;

        color: var(--warningColor);
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        text-align: left;

        & > svg {
          stroke: var(--warningColor);
        }
      }
      .c__modal {
        z-index: 55;
        .c__modal-main {
          height: 65%;
          .c__modal-title {
            font-size: 20px;
          }

          .c__modal-formTitle {
            display: flex;
            justify-content: flex-end;
            padding: 12px;
            background-color: #eaeaea;
            & > svg {
              cursor: pointer;
              width: 24px;
              height: 24px;
              fill: #000000;
            }
          }
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .c__modal-content {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .c__card-header {
        .c__card-header-title {
          font-size: 18px;
        }
      }

      .memberDetails.c__card-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: -12px;
      }
      .amount.c__card-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: -12px;
      }
    }
    .c__modal-formActions {
      justify-content: center;
      & * {
        width: unset;
      }
    }

    .note {
      text-align: center;
      color: var(--titleColor);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      text-transform: none;
    }

    .selectCardButton {
      width: 200px;
      margin: auto;
    }

    .cardOptions {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      &__radios {
        display: flex;
        align-items: flex-start;
        gap: 24px;
      }
      &__info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
      }
      .c__card-content-item {
        .c__card-content-item-sub,
        .c__card-content-item-title {
          text-align: left;
        }
      }
    }

    .futurePaymentOptions {
      &__radios {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-top: 16px;
      }
    }
  }
}
