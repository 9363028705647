@import 'variables';
@import 'mixins';
@import 'react-tooltip/dist/react-tooltip.css';
@import 'react-tabs/style/react-tabs.css';

@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-Light.otf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-LightItalic.otf);
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-Book.otf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-BookItalic.otf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-Medium.otf);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-MediumItalic.otf);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-Bold.otf);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url(./common/assets/fonts/Gotham-BoldItalic.otf);
  font-weight: 700;
  font-style: italic;
}

* {
  font-family: 'Gotham';
  font-weight: normal;
  font-style: normal;
  color: var(--titleColor);
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

:root {
  @include spread-map($theme-map-dark);
}
[data-theme='light'] {
  @include spread-map($theme-map-light);
}

.app {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--bgSecondary);
}

.container {
  width: 100%;
  background-color: var(--bgSecondary);
  transition: all 0.2s ease-in;
  min-height: calc(100vh - 51px);
}
.container.open {
  width: calc(100% - 280px);
  margin-left: 280px;
  transition: all 0.2s ease-out;
  overflow-y: scroll;
}
.sidebar-container {
  background-color: var(--bgPrimary);
}
img {
  background: none;
}
svg {
  flex: none;
}
.menu-icon-collapse {
  margin-right: 10px;
}
.flex {
  display: flex;
}

.layoutContainer {
  background: var(--bgSecondary);
  width: 100%;
  padding: 20px 30px 110px 30px;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}

// MUI DatePicker
.MuiPickersLayout-root {
  background-color: var(--bgPrimary);
  & * {
    color: var(--titleColor) !important;
    font-size: 13.982px !important;
    font-weight: 500 !important;
  }

  & .MuiPickersYear-root {
    line-height: unset;
    & > button {
      line-height: initial;
    }
    & > button.Mui-selected {
      background-color: var(--primaryColor);
    }
    & > button.Mui-disabled {
      opacity: 0.3;
    }
  }
  & .MuiPickersMonth-root {
    line-height: unset;
    & > button {
      line-height: initial;
    }
    & > button.Mui-selected {
      background-color: var(--primaryColor);
    }
    & > button.Mui-disabled {
      opacity: 0.3;
    }
  }

  & .MuiPickersDay-root.Mui-selected {
    background-color: var(--primaryColor);
  }

  & button.MuiButtonBase-root:disabled {
    opacity: 0.3;
  }
}

// MUI Time Picker
.MuiPickersLayout-root {
  & ul {
    &::-webkit-scrollbar {
      width: 3px;
    }
  }
  .MuiList-root {
    &:after {
      display: none;
    }
  }
  .MuiButtonBase-root {
    font-family: 'Gotham', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.line-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.line-clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.line-clamp-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.assessmentStatus {
  color: var(--titleColor);
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;

  width: max-content;
  padding: 4px 6px;
  border-radius: 9px;
  background-color: transparent;

  &__developing {
    background-color: var(--warningColor);
  }

  &__achieved {
    background-color: var(--successColor);
  }

  &__approval-pending {
    background-color: var(--infoColor);
  }

  &__not-attempted,
  &__reassess {
    background-color: var(--errorColor);
  }

  &__approved {
    background-color: var(--primaryColor);
  }
}

.absenceStatus {
  width: 20px;
  height: 20px;
  &--pending {
    stroke: var(--warningColor);
  }
  &--approved {
    stroke: var(--successColor);
  }
  &--declined {
    stroke: var(--errorColor);
  }
}
.align-right {
  text-align: right;
}

.filter-field-number {
  border-radius: 50%;
  background-color: var(--errorColor);
  height: 22px;
  width: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__add {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--whiteColor);

  &__text {
    color: var(--whiteColor);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    line-height: 16px;
    padding-top: 2px;
  }

  svg {
    color: var(--whiteColor);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.billing-discount-info {
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    width: 20px;
    height: 20px;
  }
}
.enrollment-discount-info {
  &-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .text {
      color: var(--successColor);
      font-size: 12px;
      font-weight: 500;
    }
  }
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: var(--bgSecondary);
  cursor: pointer;

  .text {
    color: var(--successColor);
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .tooltip-trigger {
    .tooltip-top {
      &::after {
        border-color: var(--primaryColor) transparent transparent transparent;
      }
    }

    &:hover {
      .tooltip {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.warning-pif-section {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(244, 183, 64, 0.1);
  svg {
    width: 20px;
    height: 20px;
    stroke: var(--warningColor);
  }
  .text {
    color: var(--warningColor);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    strong {
      font-weight: 700;
      color: var(--warningColor);
    }
  }
}
