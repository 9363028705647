.file-viewer {
  .c__documents-slider {
    z-index: 60;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 62.5vw;
    height: 60vh;
    background-color: var(--bgPrimary);
    border-radius: 8px;
    border: 1px solid var(--lineColor);

    &-header {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 72px;
      background: linear-gradient(
        180deg,
        var(--bgPrimary) 0%,
        rgba(28, 28, 36, 0) 100%
      );
      padding: 24px;
      & > p {
        color: var(--titleColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
      & > div > svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    // override swiper
    .swiper {
      overflow: visible;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 78%;
      height: 100%;
      &::before {
        display: block;
        content: '';
        position: absolute;
        right: 100%;
        top: 0;
        width: 15%;
        height: 100%;
        background-color: var(--bgPrimary);
        z-index: 5;
      }
      &::after {
        display: block;
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        width: 15%;
        height: 100%;
        background-color: var(--bgPrimary);
        z-index: 5;
      }
    }
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      left: -52px;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      color: white;
      &::after {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .swiper-button-next {
      width: 40px;
      height: 40px;
      right: -52px;
      transform: translateX(50%);
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      color: white;
      &::after {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .swiper-slide {
      background-color: var(--bgPrimary);
      & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .header-container {
      display: flex;
    }

    .document-title-container {
      margin-left: 15px;
      color: #85858f;
    }
  }
}
