.bulkManagementPage {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__tabs {
    display: flex;
    align-items: center;
    gap: 12px;
    &__item {
      color: var(--subTitleColor);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      padding: 12px;
      border-bottom: 4px solid transparent;
      cursor: pointer;
      &.active,
      &:hover {
        color: var(--titleColor);
        border-bottom: 4px solid var(--primaryColor);
      }
    }
  }
  &__tabPanel {
  }
}
