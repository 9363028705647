.c_appTabs {
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
  margin-bottom: 24px;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    display: none;
  }

  &_wrapper {
    display: flex;

    & > button {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.3px;
      text-transform: uppercase;

      border: none;
      padding: 0;
      &:hover {
        background-color: var(--bgPrimary) !important;
      }

      & > div {
        width: max-content;
        padding: 12px;

        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      & > .inactive_tab {
        color: var(--subTitleColor);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.3px;
        text-transform: uppercase;
        p {
          font-weight: 500;
        }
      }

      & > .active_tab {
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.3px;
        text-transform: uppercase;
        p {
          font-weight: 500;
        }
      }
    }
  }

  & > .active_underline_wrapper {
    height: 4px;
    width: 100%;
    align-self: stretch;
    background: transparent;

    & > .active_underline {
      height: 4px;
      background: #034ea2;

      transition: all 0.15s ease-out;
    }
  }
}
